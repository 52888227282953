import React, { Component } from 'react';

import '../../styles/modals/FeedbackModal.css';

class FeedbackModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      feedback: ""
    };
  }

  handleNameChange = (e) => {
    this.setState({
      name: e.target.value
    });
  }

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value
    });
  }

  handleFeedbackChange = (e) => {
    this.setState({
      feedback: e.target.value
    });
  }

  handleSend = async () => {
    const name = this.state.name;
    const email = this.state.email;
    const feedback = this.state.feedback;
    const data = await fetch('/api/feedback', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name, email, feedback }),
    });
    const dataJson = await data.json();
    if (!dataJson.success) console.log("Failed to record feedback.");

    this.props.onSend();
  }

  render() {
    return (
      <div className="feedback-modal">
        <div className="feedback-modal-header">
          Please feel free to send me any requests, suggestions, or other feedback.<br />
          Name and email address are optional.
        </div>

        <label>Name:
          <input type="text" className="input" onChange={this.handleNameChange} />
        </label>
        <label>Email:
          <input type="text" className="input" onChange={this.handleEmailChange} />
        </label>

        <textarea className="textarea" onChange={this.handleFeedbackChange} />

        <button className="feedback-modal-button" onClick={this.handleSend}>
          Send Feedback
        </button>
        <button className="feedback-modal-button" onClick={this.props.onClose}>
          Close Without Sending
        </button>
      </div>
    );
  }
}

export default FeedbackModal;
