import React, { Component } from 'react';

import 'whatwg-fetch';

import App from '../App';
import Board from './Board';
import Constants from '../Constants';
import LetterOptionDisplay from './LetterOptionDisplay';
import MessagePanel from './MessagePanel';
import QuitPanel from './QuitPanel';
import RulesModal from './modals/RulesModal';
import SearchBar from './SearchBar';
import SummaryPanel from './SummaryPanel';
import Utils from '../Utils';
import WordList from '../assets/WordList';

import '../styles/Game.css';

class Game extends Component {
  constructor(props) {
    super(props);

    const active = true;
    const loading = true;
    const showSummary = false;
    const holdCount = this.props.holdCount;
    const previewCount = this.props.previewCount;
    const expansions = this.props.expansions;
    const elapsedTurns = this.props.elapsedTurns;
    const placedTiles = this.props.elapsedTurns + (this.props.startLetters ? Object.keys(this.props.startLetters).length : 0);
    const gridArray = this.props.boardString
      ? Game.expandGridString(this.props.boardString, this.props.rows, this.props.columns)
      : Game.createEmptyGridArray(this.props.rows, this.props.columns, this.props.expansions);
    const startLetters = this.props.startLetters;
    // Only pre-populate start letters if it's a new game.
    // Loaded games have 0x0 dimensions at this point.
    if (this.props.startLetters && this.props.rows > 0) {
      Object.keys(this.props.startLetters).forEach((coordinates) => {
        const [row, column] = coordinates.split(",");
        gridArray[row][column] = this.props.startLetters[coordinates];
      });
    }

    const rowData = Array(this.props.rows).fill(null);
    const columnData = Array(this.props.columns).fill(null);
    for (let i = 0; i < this.props.rows; i++) {
      const rowStringArray = Game.getRowStringArray(gridArray, i, this.props.expansions);
      rowData[i] = Game.calculateDataForStringArray(rowStringArray);
    }
    for (let j = 0; j < this.props.columns; j++) {
      const columnStringArray = Game.getColumnStringArray(gridArray, j, this.props.expansions);
      columnData[j] = Game.calculateDataForStringArray(columnStringArray);
    }

    const totalScore = Utils.calculateTotalScore(rowData, columnData);
    const roundLetterOptions = Array(this.props.letterOptionsPerRound).fill(null);
    const selectedLetterIndex = -1;
    const heldLetters = Array(this.props.holdCount).fill(null);
    for (let i = 0; i < this.props.holdString.length; i++) {
      heldLetters[i] = this.props.holdString.charAt(i);
    }
    const selectedHoldIndex = -1;
    const previewLetters = Array(this.props.previewCount).fill(null);
    let message = null;
    if (this.props.adjacencyMode) {
      message = Constants.MESSAGE_ADJACENCY_MODE;
    } else if (this.props.incrementMode) {
      message = Constants.MESSAGE_INCREMENT_MODE;
    } else if (this.props.dropMode) {
      message = Constants.MESSAGE_DROP_MODE;
    }

    if (!this.props.spectating && this.props.expansions.length > 0) {
      if (message) {
        message += "\n\n";
      } else {
        message = "";
      }

      message += Constants.MESSAGE_EXPANDED;
    }
    const searchMessage = null;
    const visibleModal = Constants.MODAL_NONE;

    this.state = {
      active,
      loading,
      showSummary,
      startLetters,
      holdCount,
      previewCount,
      placedTiles,
      gridArray,
      expansions,
      rowData,
      columnData,
      totalScore,
      roundLetterOptions,
      selectedLetterIndex,
      heldLetters,
      selectedHoldIndex,
      previewLetters,
      message,
      searchMessage,
      elapsedTurns,
      visibleModal,
    };
  }

  async componentDidMount() {
    if (!this.props.daily) {
      this.recordAttempt();
      this.setState({
        roundLetterOptions: await this.getRoundLetterOptions(this.props.elapsedTurns),
        previewLetters: await this.getPreviewLetterOptions(this.props.elapsedTurns),
        loading: false
      });

      return;
    }

    if (this.props.spectating) {
      return fetch(`/api/games/date/${this.props.date}/player/${this.props.spectating}`)
        .then(data => data.json())
        .then(async (res) => {
          if (res.data.length >= 1) {
            const rows = res.data[0].rowData.length;
            const columns = res.data[0].columnData.length;
            this.setState({
              active: false,
              loading: false,
              showSummary: true,
              startLetters: res.data[0].startLetters,
              expansions: res.data[0].expansions,
              holdCount: res.data[0].holdCount,
              previewCount: res.data[0].previewCount,
              placedTiles: rows * columns,
              gridArray: Game.expandGridString(res.data[0].grid, rows, columns),
              rowData: res.data[0].rowData,
              columnData: res.data[0].columnData,
              totalScore: res.data[0].totalScore,
              holdPenalty: res.data[0].holdPenalty,
              roundLetterOptions: [],
              selectedLetterIndex: -1
            });
          }
        });
    }

    if (!this.props.boardString) {
      this.recordAttempt();
    }

    this.setState({
      roundLetterOptions: await this.getRoundLetterOptions(this.props.elapsedTurns),
      previewLetters: await this.getPreviewLetterOptions(this.props.elapsedTurns),
      loading: false
    });
  }

  componentDidUpdate() {
    if (this.state.active && this.state.placedTiles >= this.props.rows * this.props.columns + this.props.expansions.length) {
      this.endGame();
    }
  }

  static getRowStringArray = (gridArray, row, expansions) => {
    let rowStringArray = [];
    for (let j=0; j<gridArray[row].length; j++) {
      let contents = gridArray[row][j].join("");
      while (contents.length < (expansions.includes("" + row + "," + j) ? 2 : 1)) {
        contents += "-";
      }

      rowStringArray.push(contents);
    }

    return rowStringArray;
  }

  static getColumnStringArray = (gridArray, column, expansions) => {
    let columnStringArray = [];
    for (let i=0; i<gridArray.length; i++) {
      let contents = gridArray[i][column].join("");
      while (contents.length < (expansions.includes("" + i + "," + column) ? 2 : 1)) {
        contents += "-";
      }

      columnStringArray.push(contents);
    }

    return columnStringArray;
  }

  static calculateDataForStringArray = (stringArray) => {
    let maxTokenLength = 2;
    let data = {
      wordStart: -1,
      wordLength: -1,
      score: 0
    };

    for (let cellCount=stringArray.length; cellCount>1; cellCount--) {
      for (let start=0; start<=stringArray.length-cellCount; start++) {
        const token = stringArray.slice(start, start + cellCount).join("");
        if (token.length <= maxTokenLength) {
          continue;
        }

        if (WordList.includes(token.toLowerCase())) {
          data = {
            wordStart: start,
            wordLength: cellCount,
            score: Constants.SCORE_TABLE[token.length]
          };
          maxTokenLength = token.length;
        }
      }
    }

    return data;
  }

  isExpanded = (row, column) => {
    return this.props.expansions.includes("" + row + "," + column);
  }

  isFull = (row, column) => {
    return this.state.gridArray[row][column].length === 2 || (!this.isExpanded(row, column) && this.state.gridArray[row][column].length === 1);
  }

  checkAdjacency = (row, column) => {
    if (row > 0 && this.isFull(row - 1, column)) {
      return true;
    }
    if (row + 1 < this.props.rows && this.isFull(row + 1, column)) {
      return true;
    }
    if (column > 0 && this.isFull(row, column - 1)) {
      return true;
    }
    if (column + 1 < this.props.columns && this.isFull(row, column + 1)) {
      return true;
    }
    if (this.isExpanded(row, column) && this.state.gridArray[row][column].length === 1) {
      return true;
    }

    return false;
  }

  handleCellSelection = async (row, column) => {
    if (!this.props.gameKey || this.state.loading) {
      return;
    }

    if (this.props.dropMode) {
      row = this.props.rows - 1;
      while (row >= 0 && this.isFull(row, column)) {
        row--;
      }

      if (row < 0) {
        this.setState({
          message: Constants.MESSAGE_COLUMN_FULL
        })
        return;
      }
    }

    if (this.state.gridArray[row][column].length >= (this.props.expansions.includes("" + row + "," + column) ? 2 : 1)) {
      this.setState({
        message: Constants.MESSAGE_CELL_ALREADY_OCCUPIED
      })
      return;
    }

    if (this.props.adjacencyMode && this.state.placedTiles > 0 && !this.checkAdjacency(row, column)) {
      this.setState({
        message: Constants.MESSAGE_CELL_NOT_ADJACENT
      })
      return;
    }

    const heldLetters = this.state.heldLetters.slice();

    let selectedLetter;
    if (this.state.selectedLetterIndex > -1) {
      selectedLetter = this.state.roundLetterOptions[this.state.selectedLetterIndex];
      if (!selectedLetter) {
        this.setState({
          message: Constants.MESSAGE_NO_LETTER_SELECTED
        })
        return;
      }
    } else if (this.state.selectedHoldIndex > -1) {
      selectedLetter = heldLetters[this.state.selectedHoldIndex];
      heldLetters[this.state.selectedHoldIndex] = null;
    } else {
      this.setState({
        message: Constants.MESSAGE_NO_LETTER_SELECTED
      })
      return;
    }

    this.setState({
      loading: true
    });

    const gridArray = this.state.gridArray.slice();
    gridArray[row][column].push(selectedLetter);

    const rowData = this.state.rowData.slice();
    const updatedRowStringArray = Game.getRowStringArray(gridArray, row, this.props.expansions);
    rowData[row] = Game.calculateDataForStringArray(updatedRowStringArray);

    const columnData = this.state.columnData.slice();
    const updatedColumnStringArray = Game.getColumnStringArray(gridArray, column, this.props.expansions);
    columnData[column] = Game.calculateDataForStringArray(updatedColumnStringArray);

    const totalScore = Utils.calculateTotalScore(rowData, columnData);

    const placedTiles = this.state.placedTiles + 1;
    const elapsedTurns = this.state.elapsedTurns + 1;
    const roundLetterOptions = await this.getRoundLetterOptions(elapsedTurns);
    const previewLetters = await this.getPreviewLetterOptions(elapsedTurns);
    const selectedLetterIndex = -1;
    const selectedHoldIndex = -1;
    const message = null;
    const searchMessage = null;
    const loading = false;

    this.setState({
      loading,
      placedTiles,
      gridArray,
      rowData,
      columnData,
      totalScore,
      roundLetterOptions,
      selectedLetterIndex,
      heldLetters,
      previewLetters,
      selectedHoldIndex,
      message,
      searchMessage,
      elapsedTurns
    });

    if (this.props.daily) {
      this.recordAttempt();
    }
  }

  handleLetterOptionSelection = (selectedLetterIndex) => {
    this.setState({
      selectedLetterIndex,
      selectedHoldIndex: -1
    });
  }

  handleLetterHold = (selectedHoldIndex) => {
    if (!this.props.gameKey || !this.state.active) {
      return;
    }

    if (this.state.heldLetters[selectedHoldIndex]) {
      return;
    }

    const roundLetterOptions = this.state.roundLetterOptions.slice();

    const selectedLetter = roundLetterOptions[this.state.selectedLetterIndex];
    const heldLetters = this.state.heldLetters.slice();
    heldLetters[selectedHoldIndex] = selectedLetter;
    roundLetterOptions[this.state.selectedLetterIndex] = null;

    this.setState({
      roundLetterOptions,
      heldLetters
    });
  }

  handleHeldLetterSelection = (selectedHoldIndex) => {
    this.setState({
      selectedLetterIndex: -1,
      selectedHoldIndex
    });
  }

  handleQuitButtonClick = () => {
    if (this.props.daily && this.state.active && this.props.valid) {
      this.recordQuit();
    }

    this.props.onQuitButtonClick();
  }

  handleSearchSubmit = (query) => {
    const queryUpper = query.toUpperCase();
    let searchMessage = `${queryUpper} is not a valid word. ✗`;

    if (WordList.includes(query.toLowerCase())) {
      searchMessage = `${queryUpper} is a valid word. ✓`;
    }

    this.setState({
      searchMessage
    });
  }

  handleRulesButtonClick = () => {
    this.setState({
      visibleModal: Constants.MODAL_RULES
    });
  }

  handleModalClose = () => {
    this.setState({
      visibleModal: Constants.MODAL_NONE
    });
  }

  calculateIncrementedLetterOptionCount = (startingAt, maxLetterCount, elapsedTurns) => {
    return ((startingAt + elapsedTurns - 1) % maxLetterCount) + 1;
  }

  getRoundLetterOptions = async (elapsedTurns, randomSubsetCount) => {
    let letterOptionString;
    if (this.props.daily) {
      const dailyGameData = await App.fetchDailyGameData(elapsedTurns);
      letterOptionString = dailyGameData.gameData.letterOptions;
    } else {
      letterOptionString = this.props.letterOptionString;
    }

    if (!letterOptionString) {
      return Array(this.props.letterOptionsPerRound).fill(null);
    }

    const startIndex = (this.props.incrementMode ? 3 : this.props.letterOptionsPerRound) * elapsedTurns;
    if (startIndex >= letterOptionString.length) {
      return Array(randomSubsetCount).fill(null);
    }

    const initialLetterOptionCount = this.props.letterOptionsPerRound;
    const maxLetterOptionCount = 3;
    const letterOptionCount = this.props.incrementMode ? this.calculateIncrementedLetterOptionCount(initialLetterOptionCount, maxLetterOptionCount, elapsedTurns) : this.props.letterOptionsPerRound;
    const letterOptions = letterOptionString.slice(startIndex, startIndex + letterOptionCount).split("");

    if (!randomSubsetCount || randomSubsetCount >= letterOptionCount) {
      return letterOptions.sort();
    }

    const randomSubset = [];
    for (let i = 0; i < randomSubsetCount; i++) {
      const randomIndex = Math.floor(Math.random() * letterOptions.length);
      randomSubset.push(letterOptions[randomIndex]);
      letterOptions.splice(randomIndex, 1);
    }

    return randomSubset.sort();
  }

  static createEmptyGridArray = (rows, columns) => {
    const gridArray = [];

    for (let i = 0; i < rows; i++) {
      const gridRow = [];
      for (let j = 0; j < columns; j++) {
        gridRow.push([]);
      }

      gridArray.push(gridRow);
    }

    return gridArray;
  }

  static expandGridString = (gridString, rows, columns) => {
    const gridArray = [];
    let pointer = 0;
    for (let i = 0; i < rows; i++) {
      const gridRow = [];
      for (let j = 0; j < columns; j++) {
        const contents = [];
        if (gridString.charAt(pointer) === "[") {
          pointer++;
          while (gridString.charAt(pointer) !== "]") {
            if (gridString.charAt(pointer) !== Constants.LETTER_BLANK) {
              contents.push(gridString.charAt(pointer));
            }
            pointer++;
          }
        } else {
          if (gridString.charAt(pointer) !== Constants.LETTER_BLANK) {
            contents.push(gridString.charAt(pointer));
          }
        }

        gridRow.push(contents);
        pointer++;
      }
      gridArray.push(gridRow);
    }

    return gridArray;
  }

  compressGridArray = () => {
    let result = "";

    for (let i = 0; i < this.state.gridArray.length; i++) {
      for (let j = 0; j < this.state.gridArray[i].length; j++) {
        let contents = this.state.gridArray[i][j].join("");
        if (this.isExpanded(i, j)) {
          while (contents.length < 2) {
            contents += Constants.LETTER_BLANK;
          }
          contents = "[" + contents + "]";
        } else {
          while (contents.length < 1) {
            contents += Constants.LETTER_BLANK;
          }
        }

        result += contents;
      }
    }

    return result;
  }

  getPreviewLetterOptions = async (elapsedTurns) => {
    if (this.props.previewCount > 0) {
      return this.getRoundLetterOptions(elapsedTurns + 1, this.props.previewCount);
    }

    return [];
  }

  endGame = async () => {
    this.setState({
      active: false,
      showSummary: true
    });

    if (this.props.daily) {
      await this.recordGame();
    }

    this.props.onGameFinish();
  }

  verifyIdentity = async () => {
    const data = await fetch(`/api/checkToken?token=${this.props.authToken}`);
    const dataJson = await data.json();
    if (!dataJson.success) {
      console.log("Token mismatch.");
      this.recordTokenMismatch("");
      return false;
    }
    if (dataJson.data.userName !== this.props.playerName) {
      console.log("Token mismatch.");
      this.recordTokenMismatch(dataJson.data.userName);
      return false;
    }

    return true;
  }

  recordTokenMismatch = async (tokenName) => {
    const attemptedName = this.props.playerName;

    const data = await fetch('/api/tokenMismatches', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ attemptedName, tokenName }),
    });
    const dataJson = await data.json();
    if (!dataJson.success) console.log("Failed to record token mismatch.");
  }

  recordGame = async () => {
    if (!this.verifyIdentity()) {
      return;
    }

    const playerName = this.props.playerName;
    const date = this.props.date;
    const letterOptionsPerRound = this.props.letterOptionsPerRound;
    const valid = this.props.valid;
    const startLetters = this.props.startLetters;
    const expansions = this.props.expansions;
    const holdCount = this.props.holdCount;
    const previewCount = this.props.previewCount;
    const grid = this.compressGridArray();
    const rowData = this.state.rowData;
    const columnData = this.state.columnData;
    const totalScore = this.state.totalScore;
    const holdPenalty = this.calculateHoldPenalty();
    const adjacencyMode = this.props.adjacencyMode;
    const incrementMode = this.props.incrementMode;
    const dropMode = this.props.dropMode;

    const data = await fetch('/api/games', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ playerName, date, letterOptionsPerRound, valid, startLetters, expansions, holdCount, previewCount, grid, rowData, columnData, totalScore, holdPenalty, adjacencyMode, incrementMode, dropMode }),
    });
    const dataJson = await data.json();
    if (!dataJson.success) console.log("Failed to record game.");
  }

  recordAttempt = async () => {
    if (!this.verifyIdentity()) {
      return;
    }

    const type = this.props.daily ? Constants.GAME_TYPE_DAILY : Constants.GAME_TYPE_PRACTICE;
    const playerName = this.props.playerName;
    const date = this.props.date;
    const letterOptions = this.props.letterOptionsPerRound;
    const holdCount = this.props.holdCount;
    const previewCount = this.props.previewCount;
    const adjacencyMode = this.props.adjacencyMode;
    const incrementMode = this.props.incrementMode;
    const dropMode = this.props.dropMode;
    const boardString = this.compressGridArray();
    const elapsedTurns = this.state.elapsedTurns;
    const holdString = this.state.heldLetters.join("");

    const data = await fetch('/api/attempts', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ type, playerName, date, letterOptions, holdCount, previewCount, adjacencyMode, incrementMode, dropMode, boardString, elapsedTurns, holdString }),
    });
    const dataJson = await data.json();
    if (!dataJson.success) console.log("Failed to record attempt.");
  }

  recordQuit = async () => {
    const playerName = this.props.playerName;
    const date = this.props.date;

    const data = await fetch('/api/quits', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ playerName, date }),
    });
    const dataJson = await data.json();
    if (!dataJson.success) console.log("Failed to record quit.");
  }

  calculateHoldPenalty = () => {
    return this.state.heldLetters.reduce((acc, cur) => { return cur ? acc + Constants.PENALTY_HOLD : acc }, 0);
  }

  render() {
    return (
      <div className="game">
        <Board
          gridArray={this.state.gridArray}
          startLetters={this.state.startLetters}
          expansions={this.state.expansions}
          loading={this.state.loading}
          rowData={this.state.rowData}
          columnData={this.state.columnData}
          onCellSelection={this.handleCellSelection}
        />
        { this.state.active &&
          <LetterOptionDisplay
            letterOptions={this.state.roundLetterOptions}
            selectedLetterIndex={this.state.selectedLetterIndex}
            selectedHoldIndex={this.state.selectedHoldIndex}
            heldLetters={this.state.heldLetters}
            previewLetters={this.state.previewLetters}
            onLetterHold={this.handleLetterHold}
            onHeldLetterSelection={this.handleHeldLetterSelection}
            onLetterOptionSelection={this.handleLetterOptionSelection}
          />
        }
        { this.state.message &&
          <MessagePanel message={this.state.message} />
        }
        { this.state.showSummary &&
          <SummaryPanel
            score={this.state.totalScore}
            spectating={this.props.spectating}
            holdPenalty={this.calculateHoldPenalty()}
            showBreakdown={this.state.holdCount > 0}
          />
        }
        { this.state.active &&
          <SearchBar
            message={this.state.searchMessage}
            onSubmit={this.handleSearchSubmit}
          />
        }
        { !this.props.spectating &&
          <button className="button-rules mobile-and-tablet-only" onClick={this.handleRulesButtonClick}>
            Rules
          </button>
        }
        { (!this.props.daily || !this.state.active || !this.props.valid) &&
          <QuitPanel onQuitButtonClick={this.handleQuitButtonClick} />
        }
        { this.state.visibleModal === Constants.MODAL_RULES &&
          <RulesModal onClose={this.handleModalClose}/>
        }
      </div>
    );
  }
}

export default Game;
