import moment from 'moment-timezone';

import Constants from './Constants';

export default class Utils {
  static generateRandomLetterOptionString = (rounds, letterOptionsPerRound, sort) => {
    let randomLetterOptionString = "";
    const frequencyTotal = Constants.FREQUENCIES.reduce((a, b) => a + b.frequency, 0);

    for (let i = 0; i < rounds; i++) {
      const roundLetterOptionList = [];
      for (let j = 0; j < letterOptionsPerRound; j++) {
        let randomLetter;
        do {
          let R = Math.floor(Math.random() * frequencyTotal);
          for (let k = 0; k < Constants.FREQUENCIES.length; k++) {
            R -= Constants.FREQUENCIES[k].frequency;
            if (R < 0) {
              randomLetter = Constants.FREQUENCIES[k].letter;
              break;
            }
          }
        } while (roundLetterOptionList.includes(randomLetter));

        roundLetterOptionList.push(randomLetter);
      }

      randomLetterOptionString += roundLetterOptionList.toString().replace(/,/g, "");
    }

    return randomLetterOptionString;
  }

  static generateRandomStartLetters = (rows, columns, startLetterCount) => {
    const randomStartLetters = {};
    for (let i = 0; i < startLetterCount; i++) {
      let coordinates;
      do {
        const row = Math.floor(Math.random() * rows);
        const column = Math.floor(Math.random() * columns);
        coordinates = "" + row + "," + column;
      } while (randomStartLetters[coordinates]);

      randomStartLetters[coordinates] = [String.fromCharCode(65 + Math.floor(Math.random() * 26))];
    }

    return randomStartLetters;
  }

  static generateRandomExpansions = (rows, columns, expansionCount, disallowedCoordinates) => {
    const randomCoordinates = [];

    while (randomCoordinates.length < expansionCount) {
      const row = Math.floor(Math.random() * rows);
      const column = Math.floor(Math.random() * columns);
      const coordinates = "" + row + "," + column;

      if (!disallowedCoordinates.includes(coordinates) && !randomCoordinates.includes(coordinates)) {
        randomCoordinates.push(coordinates);
      }
    }

    return randomCoordinates;
  }

  static getDateStringToday = () => {
    return Utils.getDateStringDaysAgo(0);
  }

  static getDateStringYesterday = () => {
    return Utils.getDateStringDaysAgo(1);
  }

  static getDateStringMonday = (date) => {
    while (this.getDayOfWeek(date) !== 1) {
      date = Utils.addDays(date, -1);
    }

    return date;
  }

  static getDateStringMostRecentMonday = () => {
    return Utils.getDateStringMonday(Utils.getDateStringToday());
  }

  static getDateStringTwoMondaysAgo = () => {
    return Utils.getDateStringMonday(Utils.getDateStringDaysAgo(7));
  }

  static getDateStringDaysAgo = (daysAgo) => {
    return moment(new Date()).tz("America/Toronto").add(-daysAgo, 'days').format("YYYY-MM-DD");
  }

  static getReadableDateStringToday = () => {
    return moment(new Date()).tz("America/Toronto").format("dddd, MMMM Do, YYYY");
  }

  static getDayOfWeekToday = () => {
    return moment(new Date()).tz("America/Toronto").day();
  }

  static getDayOfWeek = (date) => {
    return moment(date).day();
  }

  static getDaysAgo = (startDateString) => {
    var today = moment(new Date());
    var start = moment(startDateString);
    return moment.duration(today.diff(start)).asDays();
  }

  static addDays = (startDateString, days) => {
    return moment(startDateString).add(days, 'days').format("YYYY-MM-DD");
  }

  static calculateTotalScore = (rowData, columnData) => {
    return rowData.reduce((a, b) => a + b.score, 0) + columnData.reduce((a, b) => a + b.score, 0);
  }

  static capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static assembleScoreHistoryString = (scores, startDate) => {
    let scoreString = "";

    for (let days = 0; days < 7; days++) {
      const date = Utils.addDays(startDate, days);
      const score = scores[date];
      scoreString += score ? score : "0";
      if (days < 6) {
        scoreString += " | ";
      }
    }

    return scoreString;
  }
}
