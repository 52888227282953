import React, { Component } from 'react';

import '../styles/PreviewCell.css';
import '../styles/Square.css';

class PreviewCell extends Component {
  render() {
    return (
      <div className="square preview-cell">
        {this.props.letter}
      </div>
    );
  }
}

export default PreviewCell;
