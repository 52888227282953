import React, { Component } from 'react';

import '../styles/NotePanel.css';

class NotePanel extends Component {
  render() {
    let panel =
      <div className="note-panel">
      </div>;

    return panel;
  }
}

export default NotePanel;
