import React, { Component } from 'react';

import '../styles/Cell.css';
import '../styles/Square.css';

import Constants from '../Constants';

class Cell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hover: false
    };
  }

  isFull = () => {
    return this.props.letters.length === 2 || (!this.props.expanded && this.props.letters.length === 1);
  }

  handleClick = () => {
    this.setState({
      hover: false
    });

    this.props.onCellSelection(this.props.row, this.props.column);
  }

  handleMouseEnter = () => {
    if (this.isFull()) {
      return;
    }

    this.setState({
      hover: true
    });
  }

  handleMouseLeave = () => {
    this.setState({
      hover: false
    });
  }

  renderLetters() {
    if (this.props.expanded) {
      return (
        <div className="cell-expanded-square">
          {this.props.letters.join("")}
        </div>
      );
    }

    if (this.props.startLetters) {
      return (
        <div className="cell-start-letter-circle">
          {this.props.letters.join("/")}
        </div>
      )
    }

    return this.props.letters.join("");
  }

  render() {
    let classes = "cell square";
    classes += " row-" + this.props.rowWordPosition;
    classes += " column-" + this.props.columnWordPosition;
    classes += (this.state.hover && !this.state.loading ? " cell-hover" : "");
    classes += (this.isFull() ? " cell-occupied" : "");
    classes += (this.props.loading ? " cell-loading" : "");

    return (
      <div
        className={classes}
        onClick={this.handleClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {this.renderLetters()}
      </div>
    );
  }
}

export default Cell;
