import React, { Component } from 'react';

import Constants from '../Constants';
import Utils from '../Utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faTrophy } from '@fortawesome/free-solid-svg-icons'

import '../styles/ScoreboardRow.css';

class ScoreboardRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hover: false
    };
  }

  handleMouseEnter = () => {
    this.setState({
      hover: true
    });
  }

  handleMouseLeave = () => {
    this.setState({
      hover: false
    });
  }

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.score.name);
    }
  }

  render() {
    const parity = this.props.index % 2 === 0 ? "even" : "odd";
    let classes = "scoreboard-row scoreboard-row-" + parity;
    classes += this.props.onClick ? " scoreboard-row-clickable" : "";
    classes += this.state.hover ? " scoreboard-row-hover" : "";

    let scoreHistory;
    if (this.props.sevenDayStartDate) {
      scoreHistory = Utils.assembleScoreHistoryString(this.props.score.history, this.props.sevenDayStartDate);
    }

    const scoreHistoryElement = (
      <div className="scoreboard-row-score-history">
        { scoreHistory }
      </div>
    );

    const scoreElement = (
      <>
        <div className="scoreboard-row-rank">
          {this.props.score.rank}
        </div>
        <div className="scoreboard-row-identity">
          { this.props.lastWeekRank !== null &&
            <FontAwesomeIcon
              icon={faTrophy}
              className={`scoreboard-row-trophy-${Constants.ACCOLADE_COLORS[this.props.lastWeekRank]}`}
              title={`${this.props.score.name} achieved last week's ${Constants.SCORE_DESCRIPTORS[this.props.lastWeekRank]} score!`}
            />
          }
          { this.props.yesterdayRank !== null &&
            <FontAwesomeIcon
              icon={faStar}
              className={`scoreboard-row-star-${Constants.ACCOLADE_COLORS[this.props.yesterdayRank]}`}
              title={`${this.props.score.name} achieved yesterday's ${Constants.SCORE_DESCRIPTORS[this.props.yesterdayRank]} score!`}
            />
          }
          <div className="scoreboard-row-name">
            {this.props.score.name}
          </div>
        </div>
        <div className="scoreboard-row-score" title={this.props.sevenDayStartDate ? `${this.props.score.games} games` : null}>
          { this.props.score.score }
        </div>
      </>
    );

    return (
      <div
        className={classes}
        onClick={this.handleClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        { (this.props.sevenDayStartDate && this.state.hover)
            ? scoreHistoryElement
            : scoreElement
        }
      </div>
    );
  }
}

export default ScoreboardRow;
