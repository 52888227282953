import React, { Component } from 'react';

import '../styles/QuitPanel.css';

class QuitPanel extends Component {
  handleQuitButtonClick = () => {
    this.props.onQuitButtonClick();
  }

  render() {
    return (
      <div className="quit-panel">
        <button onClick={this.handleQuitButtonClick}>Quit Game</button>
      </div>
    );
  }
}

export default QuitPanel;
