import React, { Component } from 'react';

import '../styles/LetterOption.css';
import '../styles/Square.css';

class LetterOption extends Component {
  handleClick = () => {
    this.props.onLetterOptionSelection(this.props.index);
  }

  render() {
    let classes = "square letter-option ";
    classes += (this.props.selected ? " selected" : "");
    classes += (this.props.letter ? "" : " unpickable")

    return (
      <div className={classes} onClick={this.handleClick}>
        {this.props.letter}
      </div>
    );
  }
}

export default LetterOption;
