import React, { Component } from 'react';

import Constants from '../../Constants';

import '../../styles/modals/ScheduleModal.css';

class ScheduleModal extends Component {
  render() {
    return (
      <div className="schedule-modal">
        <div className="schedule-modal-header">
          The daily parameters are as follows, subject to change at any time.
        </div>
        <table className="schedule-modal-table">
          <tr className="schedule-modal-table-header-row">
            <th className="schedule-modal-table-header" scope="col">Day</th>
            <th className="schedule-modal-table-header" scope="col">Grid Size</th>
            <th className="schedule-modal-table-header" scope="col">Letter Options</th>
            <th className="schedule-modal-table-header" scope="col">Starting Letters</th>
            <th className="schedule-modal-table-header" scope="col">Expanded Cells</th>
            <th className="schedule-modal-table-header" scope="col">Letter Previews</th>
            <th className="schedule-modal-table-header" scope="col">Holds</th>
            <th className="schedule-modal-table-header" scope="col">Notes</th>
          </tr>
          {[...Array(7)].map((x, i) => {
            const j = (i + 1) % 7;
            let note = "";
            if (Constants.DAILY_ADJACENCY_MODE[j]) {
              note += "Adjacency Mode, ";
            }
            if (Constants.DAILY_INCREMENT_MODE[j]) {
              note += "Increment Mode, ";
            }
            if (Constants.DAILY_DROP_MODE[j]) {
              note += "Drop Mode, ";
            }
            note = note.substring(0, note.length - 2);

            return (
              <tr className={`schedule-modal-row schedule-modal-row-${i % 2 === 0 ? "even" : "odd"}`}>
                <td className="schedule-modal-cell">{Constants.DAYS[j]}</td>
                <td className="schedule-modal-cell">{`${Constants.DAILY_ROWS[j]}x${Constants.DAILY_COLUMNS[j]}`}</td>
                <td className="schedule-modal-cell">{Constants.DAILY_LETTER_OPTIONS_PER_ROUND[j]}</td>
                <td className="schedule-modal-cell">{Constants.DAILY_START_LETTER_COUNT[j]}</td>
                <td className="schedule-modal-cell">{Constants.DAILY_EXPANSIONS[j]}</td>
                <td className="schedule-modal-cell">{Constants.DAILY_PREVIEWS[j]}</td>
                <td className="schedule-modal-cell">{Constants.DAILY_HOLDS[j]}</td>
                <td className="schedule-modal-cell">{note}</td>
              </tr>
            );
          })}
        </table>

        <button className="schedule-modal-close" onClick={this.props.onClose}>
          Close
        </button>
      </div>
    );
  }
}

export default ScheduleModal;
