import React, { Component } from 'react';

import '../styles/SearchBar.css';

class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: "",
    };
  }

  handleQueryChange = (e) => {
    if (this.state.query >= 6) {
      return;
    }

    this.setState({
      query: e.target.value
    });
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    this.props.onSubmit(this.state.query);
  }

  render() {
    return (
      <div className="search-bar">
        <form onSubmit={this.handleSubmit}>
          <fieldset className="search-bar-fieldset">
            <legend>
              Dictionary Search
            </legend>
            <div className="search-bar-fieldset-inner">
              <input
                type="text"
                className="search-bar-input"
                maxlength="8"
                onChange={this.handleQueryChange}
              />
              <div>
                <button>Look Up</button>
              </div>
            </div>
          </fieldset>
        </form>
        { this.props.message &&
          <div className="search-bar-message">
            { this.props.message }
          </div>
        }
      </div>
    );
  }
}

export default SearchBar;
