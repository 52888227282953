import React, { Component } from 'react';

import Grid from './Grid';
import Score from './Score';
import ScoreGroup from './ScoreGroup';
import Utils from '../Utils';

import '../styles/Board.css';

class Board extends Component {
  render() {
    const totalScore = Utils.calculateTotalScore(this.props.rowData, this.props.columnData);
    const rowScores = this.props.rowData.map(item => item.score);
    const columnScores = this.props.columnData.map(item => item.score);

    return (
      <div>
        { this.props.rowData.length > 0 &&
          <div>
            <div className="board-row">
              <Grid
                letters={this.props.gridArray}
                startLetters={this.props.startLetters}
                expansions={this.props.expansions}
                loading={this.props.loading}
                rowData={this.props.rowData}
                columnData={this.props.columnData}
                onCellSelection={this.props.onCellSelection}
              />
              <ScoreGroup direction="vertical" scores={rowScores} />
            </div>
            <div className="board-row">
              <ScoreGroup direction="horizontal" scores={columnScores} />
              <Score total="true" score={totalScore} />
            </div>
          </div>
        }
      </div>
    );
  }
}

export default Board;
