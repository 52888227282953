import React, { Component } from 'react';

import App from '../../App.js';
import Constants from '../../Constants.js';
import Utils from '../../Utils.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

import '../../styles/modals/ProfileModal.css';

class ProfileModal extends Component {
  handleBackfill = async () => {
    const statMap = {};

    // The "star era" began on May 28, 2019.
    for (let daysAgo = 1; daysAgo <= Utils.getDaysAgo("2019-05-28"); daysAgo++) {
      const scores = await App.fetchScoresForDate(Utils.getDateStringDaysAgo(daysAgo));
      const starMap = App.getAccoladeMap(scores);
      scores.forEach(score => {
        if (!Object.keys(statMap).includes(score.name)) {
          statMap[score.name] = {
            games: 0,
            stars: [0, 0, 0]
          }
        }

        statMap[score.name].games++;
        if (Object.keys(starMap).includes(score.name)) {
          statMap[score.name].stars[starMap[score.name]]++;
        }
      });
    }

    Object.keys(statMap).forEach(playerName => {
      fetch('/api/account/backfill', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: playerName, stats: statMap[playerName] }),
      });
    });
  }

  render() {
    const gameNoun = this.props.profile.games === 1 ? "game" : "games";

    return (
      <div className="profile-modal">
        <div className="profile-header">
          {this.props.profile.name}
        </div>
        <div className="profile-games">
          {this.props.profile.games} {gameNoun} played
        </div>
        <fieldset className="profile-fieldset">
          <legend className="profile-legend">
            Accolades
          </legend>
          <div className="profile-fieldset-inner">
            <div className="profile-stars">
              {this.props.profile.stars && this.props.profile.stars.map((starCount, i) => {
                return (
                  <div className="profile-star-group">
                    <div className="profile-star-div">
                      <FontAwesomeIcon
                        icon={faStar}
                        className={`profile-star profile-star-${Constants.ACCOLADE_COLORS[i]}`}
                        title={`${Utils.capitalize(Constants.ACCOLADE_COLORS[i])} stars are awarded for achieving a day's ${Constants.SCORE_DESCRIPTORS[i]} score.`}
                      />
                    </div>
                    <div className="profile-star-count">
                      {starCount}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </fieldset>

        <div className="profile-note">
        </div>

        <button className="profile-modal-close" onClick={this.props.onClose}>
          Close
        </button>
      </div>
    );
  }
}

export default ProfileModal;
