import React, { Component } from 'react';

import '../styles/ModePanel.css';

class ModePanel extends Component {
  render() {
    return (
      <div className="mode-panel">
        {this.props.modes.join(", ")}
      </div>
    );
  }
}

export default ModePanel;
