import React, { Component } from 'react';

import '../styles/HoldCell.css';
import '../styles/Square.css';

class HoldCell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hover: false
    };
  }

  handleClick = () => {
    if (this.props.letter) {
      this.props.onHeldLetterSelection(this.props.index);
    } else {
      this.props.onLetterHold(this.props.index);
    }

    this.setState({
      hover: false
    });
  }

  handleMouseEnter = () => {
    this.setState({
      hover: true
    });
  }

  handleMouseLeave = () => {
    this.setState({
      hover: false
    });
  }

  render() {
    let classes = "square hold-cell";
    classes += (this.state.hover ? " hover" : "");
    classes += (this.props.letter ? " occupied" : "");
    classes += (this.props.selected ? " selected" : "");

    return (
      <div
        className={classes}
        onClick={this.handleClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {this.props.letter}
      </div>
    );
  }
}

export default HoldCell;
