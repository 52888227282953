import React, { Component } from 'react';

import '../styles/Score.css';
import '../styles/Square.css';

import Constants from '../Constants';

class Score extends Component {
  render() {
    let className = "square score" + (this.props.total === "true" ? " total" : "");

    return (
      <div className={className}>
        {this.props.score}
      </div>
    );
  }
}

export default Score;
