import React, { Component } from 'react';

import '../styles/SummaryPanel.css';

class SummaryPanel extends Component {
  render() {
    const totalScore = this.props.score + this.props.holdPenalty;
    const breakdown = <div>
      Board score: {this.props.score}<br />
      Hold penalty: {this.props.holdPenalty}<br />
    </div>

    const scorer = this.props.spectating ? this.props.spectating + "'s" : "Your";
    const conjugatedBe = this.props.spectating ? "was": "is";

    return (
      <div className="summary-panel">
        { this.props.showBreakdown && breakdown }
        { scorer } final score { conjugatedBe } {totalScore}.
      </div>
    );
  }
}

export default SummaryPanel;
