import React, { Component } from 'react';

import Constants from '../Constants';
import ScoreboardRow from './ScoreboardRow';
import Utils from '../Utils';

import '../styles/Scoreboard.css';

class Scoreboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      periodIndex: 0
    };
  }

  handleLeftArrowClick = () => {
    const periodCount = Constants.SCORE_PERIODS.length;

    this.setState({
      periodIndex: (this.state.periodIndex + periodCount - 1) % periodCount
    });
  }

  handleRightArrowClick = () => {
    const periodCount = Constants.SCORE_PERIODS.length;

    this.setState({
      periodIndex: (this.state.periodIndex + 1) % periodCount
    });
  }

  handleRowClick = (name) => {
    const period = Constants.SCORE_PERIODS[this.state.periodIndex];
    let date;
    if (period === Constants.SCORES_TODAY) {
      date = Utils.getDateStringToday();
    } else if (period === Constants.SCORES_YESTERDAY) {
      date = Utils.getDateStringYesterday();
    } else {
      return;
    }

    this.props.onClick(name, date);
  }

  render() {
    const period = Constants.SCORE_PERIODS[this.state.periodIndex];
    const clickable = period === Constants.SCORES_YESTERDAY || (period === Constants.SCORES_TODAY && this.props.clickableToday);
    const scores = this.props.scores[period].scores;
    const gameCount = this.props.scores[period].gameCount;
    const pluralChar = gameCount === 1 ? "" : "s";

    let gameCountString;
    if (period === Constants.SCORES_LAST_WEEK || period === Constants.SCORES_THIS_WEEK) {
      gameCountString = `${gameCount} eligible player${pluralChar}`;
    } else {
      gameCountString = `${gameCount} completed game${pluralChar}`;
    }

    return (
      <div className="scoreboard">
        <div className="scoreboard-title-bar">
          <div
            className="scoreboard-arrow scoreboard-arrow-left"
            onClick={this.handleLeftArrowClick}
          >
            &lt;
          </div>
          <div className="scoreboard-title">
            {this.props.scores[period].title}
          </div>
          <div
            className="scoreboard-arrow scoreboard-arrow-right"
            onClick={this.handleRightArrowClick}
          >
            &gt;
          </div>
        </div>
        {scores && scores.map((score, i) => {
          let yesterdayRank = null;
          if (period === Constants.SCORES_TODAY && Object.keys(this.props.starMap).includes(score.name)) {
            yesterdayRank = this.props.starMap[score.name];
          }

          let lastWeekRank = null;
          if (period === Constants.SCORES_TODAY && Object.keys(this.props.trophyMap).includes(score.name)) {
            lastWeekRank = this.props.trophyMap[score.name];
          }

          let sevenDayStartDate = null;
          if (period === Constants.SCORES_THIS_WEEK) {
            sevenDayStartDate = Utils.getDateStringMostRecentMonday();
          } else if (period === Constants.SCORES_LAST_WEEK) {
            sevenDayStartDate = Utils.getDateStringTwoMondaysAgo();
          }

          return (
            <ScoreboardRow
              key={`scoreboard_row_${i}`}
              index={i}
              score={score}
              sevenDayStartDate={sevenDayStartDate}
              yesterdayRank={yesterdayRank}
              lastWeekRank={lastWeekRank}
              onClick={clickable ? this.handleRowClick : null}
            />
          );
        })}
        {gameCount !== undefined &&
          <div className="scoreboard-footer">
            {gameCountString}
          </div>
        }
      </div>
    );
  }
}

export default Scoreboard;
