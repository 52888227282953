import React, { Component } from 'react';

import Constants from './Constants';
import ControlPanel from './components/ControlPanel';
import DatePanel from './components/DatePanel';
import FeedbackModal from './components/modals/FeedbackModal';
import FeedbackThanksModal from './components/modals/FeedbackThanksModal';
import Footer from './components/Footer';
import Game from './components/Game';
import Header from './components/Header';
import LeaderboardModal from './components/modals/LeaderboardModal';
import LoginModal from './components/modals/LoginModal';
import ModePanel from './components/ModePanel';
import ProfileModal from './components/modals/ProfileModal';
import RulesModal from './components/modals/RulesModal';
import RulesPanel from './components/RulesPanel';
import ScheduleModal from './components/modals/ScheduleModal';
import Scoreboard from './components/Scoreboard';
import Utils from './Utils';

import './App.css';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authToken: "",
      loggedInPlayerName: null,
      profile: null,
      dailyAttempted: Constants.ATTEMPT_NONE,
      dailyScore: null,
      sevenDayScoreString: null,
      gameKey: null,
      liveGame: false,
      daily: true,
      rows: Constants.DEFAULT_ROWS,
      columns: Constants.DEFAULT_COLUMNS,
      startLetterCount: 0,
      holdCount: 0,
      previewCount: 0,
      expansionCount: 0,
      adjacencyMode: false,
      incrementMode: false,
      dropMode: false,
      letterOptionsPerRound: 0,
      letterOptionString: null,
      scores: {},
      visibleModal: Constants.MODAL_NONE,
      spectating: null,
      yesterdayStarMap: {},
      lastWeekTrophyMap: {},
      boardString: null,
      elapsedTurns: 0,
      holdString: "",
      game: null
    };
    this.state.scores[Constants.SCORES_TODAY] = {};
    this.state.scores[Constants.SCORES_YESTERDAY] = {};
    this.state.scores[Constants.SCORES_THIS_WEEK] = {};
    this.state.scores[Constants.SCORES_LAST_WEEK] = {};
  }

  componentDidMount() {
    this.fetchIdentity();
    this.fetchScores();

    window.addEventListener('beforeunload', (e) => {
      if (this.state.liveGame) {
        e.preventDefault();
        e.returnValue = "You have a game in progress.  Are you sure you want to exit?";
      }
    });
  }

  fetchIdentity = async () => {
    const tokenData = await fetch(`/api/checkToken?token=${this.state.authToken}`);
    const tokenDataJson = await tokenData.json();
    if (tokenDataJson.success) {
      const authToken = tokenDataJson.data.token;
      const loggedInPlayerName = tokenDataJson.data.userName;
      const attempt = await this.getAttempt(loggedInPlayerName);
      const dailyAttempted = attempt.attempt;
      const dailyScore = attempt.score;
      const sevenDayScoreString = await this.fetchSevenDayScoreString(loggedInPlayerName);
      const profile = await this.getProfile(loggedInPlayerName);
      const game = await this.getGame(loggedInPlayerName);

      this.setState({
        authToken,
        loggedInPlayerName,
        dailyAttempted,
        dailyScore,
        sevenDayScoreString,
        profile,
        game
      });
    } else {
      this.setState({
        authToken: "",
        loggedInPlayerName: null,
        dailyAttempted: Constants.ATTEMPT_NONE,
        dailyScore: null,
        sevenDayScoreString: null,
        profile: null,
        game: null
      });
    }
  }

  getGame = async (playerName) => {
    const date = Utils.getDateStringToday();

    const gameData = await fetch(`/api/games/date/${date}/player/${playerName}`);
    const gameDataJson = await gameData.json();
    if (gameDataJson.data.length >= 1) {
      const game = gameDataJson.data[0];
      const rows = game.rowData.length;
      const columns = game.columnData.length;

      return {
        startLetters: game.startLetters,
        gridArray: Game.expandGridString(game.grid, rows, columns),
        rowData: game.rowData,
        columnData: game.columnData,
      };
    }
  }

  fetchScores = async () => {
    const scoresToday = await App.fetchScoresForDate(Utils.getDateStringToday());
    const scoresYesterday = await App.fetchScoresForDate(Utils.getDateStringYesterday());
    const scoresThisWeek = await App.fetchScoresForWeek(Utils.getDateStringToday());
    const scoresLastWeek = await App.fetchScoresForWeek(Utils.getDateStringDaysAgo(7));

    const yesterdayStarMap = App.getAccoladeMap(scoresYesterday);
    const lastWeekTrophyMap = App.getAccoladeMap(scoresLastWeek);

    const scores = {};
    scores[Constants.SCORES_TODAY] = {
      title: "Today's Top Ten",
      scores: App.sliceScores(scoresToday, 10),
      gameCount: scoresToday.length
    };
    scores[Constants.SCORES_YESTERDAY] = {
      title: "Yesterday's Top Ten",
      scores: App.sliceScores(scoresYesterday, 10),
      gameCount: scoresYesterday.length
    };
    scores[Constants.SCORES_THIS_WEEK] = {
      title: "This Week's Top Ten",
      scores: App.sliceScores(scoresThisWeek, 10),
      gameCount: scoresThisWeek.length
    };
    scores[Constants.SCORES_LAST_WEEK] = {
      title: "Last Week's Top Ten",
      scores: App.sliceScores(scoresLastWeek, 10),
      gameCount: scoresLastWeek.length
    };

    this.setState({
      scores,
      yesterdayStarMap,
      lastWeekTrophyMap,
    });
  }

  getAttempt = async (playerName) => {
    const date = Utils.getDateStringToday();

    const gameData = await fetch(`/api/games/date/${date}/player/${playerName}`);
    const gameDataJson = await gameData.json();
    if (gameDataJson.data.length >= 1) {
      return {
        attempt: Constants.ATTEMPT_COMPLETE,
        score: gameDataJson.data[0].totalScore + gameDataJson.data[0].holdPenalty
      };
    }

    const data = await fetch(`/api/attempts/date/${date}/player/${playerName}`);
    const dataJson = await data.json();
    if (dataJson.data.length >= 1) {
      return {
        attempt: Constants.ATTEMPT_INCOMPLETE,
        score: null
      };
    }

    return {
      attempt: Constants.ATTEMPT_NONE,
      score: null
    };
  }

  fetchSevenDayScoreString = async (playerName) => {
    const date = Utils.getDateStringMostRecentMonday();
    const scoreData = await fetch(`/api/games/date/${date}/player/${playerName}/dayCount/7`);
    const scoreDataJson = await scoreData.json();

    const scoreMap = {};
    let totalScore = 0;
    scoreDataJson.data.filter(data => {
      return typeof data.valid !== 'boolean' || data.valid === true;
    }).forEach(data => {
      const score = data.totalScore + data.holdPenalty;
      scoreMap[data.date] = score;
      totalScore += score;
    });

    let scoreString = Utils.assembleScoreHistoryString(scoreMap, Utils.getDateStringMostRecentMonday());
    scoreString += ` || ${totalScore}`;

    return scoreString;
  }

  getProfile = async (playerName) => {
    const playerData = await fetch(`/api/account/${playerName}`);
    const playerDataJson = await playerData.json();
    return playerDataJson.data;
  }

  static getAccoladeMap = (scores) => {
    let index = 0;
    let rank = 0;
    let curScore = scores[0].score;
    const accoladeMap = {};
    while (rank <= 2) {
      while (scores[index].score === curScore) {
        accoladeMap[scores[index].name] = rank;
        index++;
      }
      curScore = scores[index].score;
      rank++;
    }

    return accoladeMap;
  }

  static updateStats = async () => {
    const scores = await App.fetchScoresForDate(Utils.getDateStringYesterday());
    const starMap = App.getAccoladeMap(scores);

    fetch('/api/updateStats', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ scores, starMap }),
    });
  }

  static sliceScores = (scores, minCount) => {
    if (scores.length === 0) {
      return [];
    }
    if (scores.length < minCount) {
      minCount = scores.length;
    }

    const borderScore = scores[minCount - 1].score;
    const slicedScores = [];
    let index = 0;
    let currentScore = -1;
    let rank = -1;

    while (index < scores.length && scores[index].score >= borderScore) {
      if (scores[index].score !== currentScore) {
        currentScore = scores[index].score;
        rank = index + 1;
      }

      slicedScores.push({
        rank: rank,
        name: scores[index].name,
        score: scores[index].score,
        games: scores[index].games,
        history: scores[index].history
      })

      index++;
    }

    return slicedScores;
  }

  static fetchScoresForDate = async (date) => {
    return fetch(`/api/games/date/${date}`)
      .then(data => data.json())
      .then((res) => {
        const scores = [];

        for (let i = 0; i < res.data.length; i++) {
          const valid = res.data[i].valid;
          if (typeof valid === 'boolean' && valid === false) {
            continue;
          }

          const holdPenalty = res.data[i].holdPenalty || 0;
          scores.push({
            name: res.data[i].playerName,
            score: res.data[i].totalScore + holdPenalty,
            games: 1
          });
        }

        scores.sort((a, b) => {
          return b.score - a.score;
        });

        return scores;
      });
  }

  static fetchScoresForWeek = async (date) => {
    const monday = Utils.getDateStringMonday(date);

    const data = await fetch(`/api/games/date/${monday}/dayCount/7`);
    const dataJson = await data.json();
    const scoreMap = {};

    for (let i = 0; i < dataJson.data.length; i++) {
      const valid = dataJson.data[i].valid;
      if (typeof valid === 'boolean' && valid === false) {
        continue;
      }

      const playerName = dataJson.data[i].playerName;
      if (!(playerName in scoreMap)) {
        scoreMap[playerName] = {
          score: 0,
          games: 0,
          history: {}
        }
      }
      const holdPenalty = dataJson.data[i].holdPenalty || 0;
      const score = dataJson.data[i].totalScore + holdPenalty;

      // Add scores if this isn't April Fools day 2022 (French).
      if (dataJson.data[i].date !== "2022-04-01") {
        scoreMap[playerName].score += score;
        scoreMap[playerName].games++;
        scoreMap[playerName].history[dataJson.data[i].date] = score;
      }
    }

    const scoreArray = [];
    for (let playerName in scoreMap) {
      scoreArray.push({
        name: playerName,
        score: scoreMap[playerName].score,
        games: scoreMap[playerName].games,
        history: scoreMap[playerName].history
      });
    }

    scoreArray.sort((a, b) => {
      return b.score - a.score;
    });

    return scoreArray;
  }

  static fetchDailyGameData = async (elapsedTurns) => {
    return fetch(`/api/dailyGameData/${elapsedTurns}`)
      .then(data => data.json())
      .then(res => res.data);
  }

  handleScoreboardClick = (playerName, date) => {
    this.handleStartButtonClick(Constants.GAME_TYPE_DAILY, false, playerName, date, 0, 0, 0, 0, 0, 0, 0);
  }

  handleStartButtonClick = async (gameType, liveGame, spectating, date, rows, columns, letterOptionsPerRound, startLetterCount, holdCount, previewCount, expansionCount, adjacencyMode, incrementMode, dropMode) => {
    const gameKey = Date.now();
    const daily = (gameType === Constants.GAME_TYPE_DAILY);
    const rounds = (rows * columns) + expansionCount - startLetterCount;
    const boardString = null;
    const elapsedTurns = 0;
    let letterOptionString;
    let startLetters;
    let expansions;

    if (daily) {
      const data = await App.fetchDailyGameData(/* elapsedTurns */ 0);
      letterOptionString = data.gameData.letterOptions;
      startLetters = data.gameData.startLetters;
      expansions = data.gameData.expansions;

      if (data.generatedLetters) {
        await App.updateStats();
      }
    } else {
      letterOptionString = Utils.generateRandomLetterOptionString(rounds, incrementMode ? 3 : letterOptionsPerRound);
      startLetters = Utils.generateRandomStartLetters(rows, columns, startLetterCount);
      expansions = Utils.generateRandomExpansions(rows, columns, expansionCount, Object.keys(startLetters));
    }

    this.setState({
      gameKey, liveGame, date, daily, rows, columns, letterOptionsPerRound, startLetters, expansions, holdCount, previewCount, expansionCount, adjacencyMode, incrementMode, dropMode, letterOptionString, spectating, boardString, elapsedTurns
    });
  }

  handleContinueButtonClick = async (boardString, elapsedTurns, holdString) => {
    const gameKey = Date.now();
    const liveGame = true;
    const date = Utils.getDateStringToday();
    const daily = true;
    const day = Utils.getDayOfWeekToday();
    const rows = Constants.DAILY_ROWS[day];
    const columns = Constants.DAILY_COLUMNS[day];
    const letterOptionsPerRound = Constants.DAILY_LETTER_OPTIONS_PER_ROUND[day];
    const holdCount = Constants.DAILY_HOLDS[day];
    const previewCount = Constants.DAILY_PREVIEWS[day];
    const expansionCount = Constants.DAILY_EXPANSIONS[day];
    const adjacencyMode = Constants.DAILY_ADJACENCY_MODE[day];
    const incrementMode = Constants.DAILY_INCREMENT_MODE[day];
    const dropMode = Constants.DAILY_DROP_MODE[day];
    const data = await App.fetchDailyGameData(elapsedTurns);
    const letterOptionString = data.gameData.letterOptions;
    const startLetters = data.gameData.startLetters;
    const expansions = data.gameData.expansions;
    const spectating = false;

    this.setState({
      gameKey, liveGame, date, daily, rows, columns, letterOptionsPerRound, startLetters, expansions, holdCount, previewCount, expansionCount, adjacencyMode, incrementMode, dropMode, letterOptionString, spectating, boardString, elapsedTurns, holdString
    });
  }

  handleGameFinish = () => {
    this.fetchScores();
    this.fetchIdentity();

    this.setState({
      liveGame: false
    });
  }

  handleQuitButtonClick = () => {
    this.setState({
      gameKey: null,
      liveGame: false
    });
  }

  handleModalClose = () => {
    this.setState({
      visibleModal: Constants.MODAL_NONE
    });
  }

  handleScheduleClick = () => {
    this.setState({
      visibleModal: Constants.MODAL_SCHEDULE
    });
  }

  handleFeedbackClick = () => {
    this.setState({
      visibleModal: Constants.MODAL_FEEDBACK
    });
  }

  handleFeedbackSend = () => {
    this.setState({
      visibleModal: Constants.MODAL_FEEDBACK_THANKS
    });
  }

  handleLoginClick = () => {
    this.setState({
      visibleModal: Constants.MODAL_LOGIN
    });
  }

  handleProfileClick = () => {
    this.setState({
      visibleModal: Constants.MODAL_PROFILE
    });
  }

  handleLeaderboardButtonClick = () => {
    this.setState({
      visibleModal: Constants.MODAL_LEADERBOARD
    });
  }

  handleRulesButtonClick = () => {
    this.setState({
      visibleModal: Constants.MODAL_RULES
    });
  }

  handleReviewButtonClick = () => {
    this.handleStartButtonClick(
      Constants.GAME_TYPE_DAILY,
      false,
      this.state.loggedInPlayerName,
      Utils.getDateStringToday(),
      0, 0, 0, 0, 0, 0, 0);
  }

  handleLogoutClick = () => {
    return fetch(`/api/account/logout`)
      .then(data => data.json())
      .then(res => {
        this.setState({
          authToken: "",
          liveGame: false,
          gameKey: null
        }, () => {
          this.fetchIdentity();
        });
      });
  }

  handleLoginSubmit = (token) => {
    this.setState({
      authToken: token,
    }, () => {
      this.handleModalClose();
      this.fetchIdentity();
    });
  }

  render() {
    let mainPanel = <ControlPanel
      playerName={this.state.loggedInPlayerName}
      dailyAttempted={this.state.dailyAttempted}
      dailyScore={this.state.dailyScore}
      game={this.state.game}
      sevenDayScoreString={this.state.sevenDayScoreString}
      onLoginClick={this.handleLoginClick}
      onStartButtonClick={this.handleStartButtonClick}
      onContinueButtonClick={this.handleContinueButtonClick}
      onLeaderboardButtonClick={this.handleLeaderboardButtonClick}
      onRulesButtonClick={this.handleRulesButtonClick}
      onReviewButtonClick={this.handleReviewButtonClick}
    />;
    if (this.state.gameKey) {
      mainPanel = <Game
        authToken={this.state.authToken}
        key={this.state.gameKey}
        gameKey={this.state.gameKey}
        date={this.state.date}
        rows={this.state.rows}
        columns={this.state.columns}
        daily={this.state.daily}
        valid={!this.state.dailyScore}
        expansions={this.state.expansions}
        startLetters={this.state.startLetters}
        holdCount={this.state.holdCount}
        previewCount={this.state.previewCount}
        adjacencyMode={this.state.adjacencyMode}
        incrementMode={this.state.incrementMode}
        dropMode={this.state.dropMode}
        playerName={this.state.loggedInPlayerName}
        letterOptionsPerRound={this.state.letterOptionsPerRound}
        letterOptionString={this.state.letterOptionString}
        onGameFinish={this.handleGameFinish}
        onQuitButtonClick={this.handleQuitButtonClick}
        spectating={this.state.spectating}
        boardString={this.state.boardString}
        elapsedTurns={this.state.elapsedTurns}
        holdString={this.state.holdString}
      />;
    }

    const modes = [];
    if (this.state.adjacencyMode) {
      modes.push(Constants.MODE_NAME_ADJACENCY);
    }
    if (this.state.incrementMode) {
      modes.push(Constants.MODE_NAME_INCREMENT);
    }
    if (this.state.dropMode) {
      modes.push(Constants.MODE_NAME_DROP);
    }
    if (modes.length === 0) {
      modes.push(Constants.MODE_NAME_STANDARD);
    }

    return (
      <div className="app">
        <Header
          loggedInPlayerName={this.state.loggedInPlayerName}
          onLoginClick={this.handleLoginClick}
          onLogoutClick={this.handleLogoutClick}
          onProfileClick={this.handleProfileClick}
        />
        <div className="app-main">
          <div className="app-main-left-panel">
            <RulesPanel />
          </div>
          <div className="app-main-center-panel">
            <DatePanel />
            { this.state.gameKey && 
              <ModePanel
                modes={modes}
              />
            }
            {mainPanel}
          </div>
          <div className="app-main-right-panel">
            <Scoreboard
              clickableToday={this.state.loggedInPlayerName && this.state.dailyAttempted === Constants.ATTEMPT_COMPLETE && !this.state.liveGame}
              scores={this.state.scores}
              starMap={this.state.yesterdayStarMap}
              trophyMap={this.state.lastWeekTrophyMap}
              onClick={this.handleScoreboardClick}
            />
            <div className="app-scoreboard-note">
              Click a player&#39;s name to see their daily board.<br />
              <br />
              You can view today&#39;s boards only if you&#39;re logged in and have played the daily.<br />
              <br />
              Stars are awarded to the previous day's top scorers, and trophies are awarded for the previous week's top scorers.<br />
              <br />
              Click your username in the header to see your star counts!<br />
              <br />
              Hover over a player&#39;s weekly score to see their individual scores for for that week.
            </div>
          </div>
        </div>
        <Footer onScheduleClick={this.handleScheduleClick} onFeedbackClick={this.handleFeedbackClick} />

        { this.state.visibleModal === Constants.MODAL_SCHEDULE &&
          <ScheduleModal onClose={this.handleModalClose} />
        }
        { this.state.visibleModal === Constants.MODAL_FEEDBACK &&
          <FeedbackModal onSend={this.handleFeedbackSend} onClose={this.handleModalClose} />
        }
        { this.state.visibleModal === Constants.MODAL_FEEDBACK_THANKS &&
          <FeedbackThanksModal onClose={this.handleModalClose} />
        }
        { this.state.visibleModal === Constants.MODAL_LOGIN &&
          <LoginModal onSubmit={this.handleLoginSubmit} onCancel={this.handleModalClose} />
        }
        { this.state.visibleModal === Constants.MODAL_PROFILE &&
          <ProfileModal profile={this.state.profile} onClose={this.handleModalClose}/>
        }
        { this.state.visibleModal === Constants.MODAL_LEADERBOARD &&
          <LeaderboardModal
            clickableToday={this.state.loggedInPlayerName && this.state.dailyAttempted === Constants.ATTEMPT_COMPLETE && !this.state.liveGame}
            scores={this.state.scores}
            yesterdayStarMap={this.state.yesterdayStarMap}
            lastWeekTrophyMap={this.state.lastWeekTrophyMap}
            onClick={this.handleScoreboardClick}
            onClose={this.handleModalClose}
          />
        }
        { this.state.visibleModal === Constants.MODAL_RULES &&
          <RulesModal onClose={this.handleModalClose}/>
        }
      </div>
    );
  }
}

export default App;
