import React, { Component } from 'react';

import '../../styles/modals/FeedbackThanksModal.css';

class FeedbackThanksModal extends Component {
  render() {
    return (
      <div className="feedback-thanks-modal">
        <div className="feedback-thanks-modal-header">
          Thank you very much for your feedback!
        </div>

        <button className="feedback-thanks-modal-close" onClick={this.props.onClose}>
          Close
        </button>
      </div>
    );
  }
}

export default FeedbackThanksModal;
