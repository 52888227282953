import React, { Component } from 'react';

import Constants from '../Constants';
import Cell from './Cell';

import '../styles/Grid.css';

class Grid extends Component {
  isStartLetter = (row, column) => {
    return this.props.startLetters && Object.keys(this.props.startLetters).includes("" + row + "," + column);
  }

  renderGrid = () => {
    let grid = [];

    for (let i=0; i<this.props.letters.length; i++) {
      let row = [];

      for (let j=0; j<this.props.letters[0].length; j++) {
        const rowWordStart = this.props.rowData[i].wordStart;
        const rowWordEnd = rowWordStart + this.props.rowData[i].wordLength - 1;
        let rowWordPosition = Constants.WORD_POSITION_NONE;
        if (j === rowWordStart) {
          rowWordPosition = Constants.WORD_POSITION_START;
        } else if (j === rowWordEnd) {
          rowWordPosition = Constants.WORD_POSITION_END;
        } else if (j > rowWordStart && j < rowWordEnd) {
          rowWordPosition = Constants.WORD_POSITION_MIDDLE;
        }

        const columnWordStart = this.props.columnData[j].wordStart;
        const columnWordEnd = columnWordStart + this.props.columnData[j].wordLength - 1;
        let columnWordPosition = Constants.WORD_POSITION_NONE;
        if (i === columnWordStart) {
          columnWordPosition = Constants.WORD_POSITION_START;
        } else if (i === columnWordEnd) {
          columnWordPosition = Constants.WORD_POSITION_END;
        } else if (i > columnWordStart && i < columnWordEnd) {
          columnWordPosition = Constants.WORD_POSITION_MIDDLE;
        }

        row.push(
          <Cell
            key={`row_${i}_col_${j}`}
            row={i}
            column={j}
            rowWordPosition={rowWordPosition}
            columnWordPosition={columnWordPosition}
            letters={this.props.letters[i][j]}
            startLetters={this.isStartLetter(i, j)}
            expanded={this.props.expansions.includes("" + i + "," + j)}
            loading={this.props.loading}
            onCellSelection={this.props.onCellSelection}
          />);
      }

      grid.push(
        <div key={`row_${i}`} className="grid-row">{row}</div>
      );
    }

    return grid;
  }

  render() {
    return (
      <div className="grid">
        {this.renderGrid()}
      </div>
    );
  }
}

export default Grid;
