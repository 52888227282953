import React, { Component } from 'react';

import Constants from '../../Constants';

import '../../styles/modals/RulesModal.css';

class RulesModal extends Component {
  render() {
    const score3 = `${Constants.SCORE_TABLE[3]} point${Constants.SCORE_TABLE[3] === 1 ? "" : "s"}`;
    const score4 = `${Constants.SCORE_TABLE[4]} point${Constants.SCORE_TABLE[4] === 1 ? "" : "s"}`;
    const score5 = `${Constants.SCORE_TABLE[5]} point${Constants.SCORE_TABLE[5] === 1 ? "" : "s"}`;
    const score6 = `${Constants.SCORE_TABLE[6]} point${Constants.SCORE_TABLE[6] === 1 ? "" : "s"}`;
    const score7 = `${Constants.SCORE_TABLE[7]} point${Constants.SCORE_TABLE[7] === 1 ? "" : "s"}`;
    const score8 = `${Constants.SCORE_TABLE[8]} point${Constants.SCORE_TABLE[8] === 1 ? "" : "s"}`;

    return (
      <div className="rules-modal">
        <div className="rules-modal-section">
          On each turn, select one of the letter options and enter it into the grid.
        </div>
        <div className="rules-modal-section">
          Unchosen letters do not carry over; you&#39;ll get a new set of letters each turn.
        </div>
        <div className="rules-modal-section">
          In each row and column, the longest word scores points based on its length:
          <ul>
            <li className="rules-modal-bullet">3 letters → {score3}</li>
            <li className="rules-modal-bullet">4 letters → {score4}</li>
            <li className="rules-modal-bullet">5 letters → {score5}</li>
            <li className="rules-modal-bullet">6 letters → {score6}</li>
            <li className="rules-modal-bullet">7 letters → {score7}</li>
            <li className="rules-modal-bullet">8 letters → {score8}</li>
          </ul>
        </div>
        <div className="rules-modal-section">
          In games with holds, unused held tiles are worth {Constants.PENALTY_HOLD} points, so make sure to use them!
        </div>
        <div className="rules-modal-section">
          You get only <span className="span-bold">one attempt</span> at the daily game each day (Eastern time).
        </div>
        <div className="rules-modal-section">
          Everyone who plays the daily gets the same set of letters, and scores are recorded on the leaderboard.
        </div>
        <div className="rules-modal-section">
          Free play mode has no play count restriction—play as much as you like!
        </div>
        <div className="rules-modal-section">
          Ten Words currently uses Scrabble's <span className="span-book-title">Official Tournament and Club Word List, 2006 Edition</span>.
        </div>
        <button className="rules-modal-close" onClick={this.props.onClose}>
          Close
        </button>
      </div>
    );
  }
}

export default RulesModal;
