import React, { Component } from 'react';

import HoldCellDisplay from './HoldCellDisplay';
import LetterOption from './LetterOption';
import PreviewCellDisplay from './PreviewCellDisplay';

import '../styles/LetterOptionDisplay.css';

class LetterOptionDisplay extends Component {
  render() {
    return (
      <div className="letter-option-display">
        { this.props.previewLetters.length > 0 &&
          <PreviewCellDisplay letters={this.props.previewLetters} />
        }
        <div className="letter-option-div">
          {this.props.letterOptions && this.props.letterOptions.map((letterOption, i) => {
            return (
              <LetterOption
                key={`option_${i}`}
                index={i}
                letter={letterOption}
                selected={i === this.props.selectedLetterIndex}
                onLetterOptionSelection={this.props.onLetterOptionSelection}
              />
            );
          })}
        </div>
        { this.props.heldLetters.length > 0 &&
          <HoldCellDisplay
            letters={this.props.heldLetters}
            selectedIndex={this.props.selectedHoldIndex}
            onLetterHold={this.props.onLetterHold}
            onHeldLetterSelection={this.props.onHeldLetterSelection}
          />
        }
      </div>
    );
  }
}

export default LetterOptionDisplay;
