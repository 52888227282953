import React, { Component } from 'react';

import '../styles/Footer.css';

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="footer-tab-bar">
          <div className="footer-tab" onClick={this.props.onScheduleClick}>
            Daily Schedule
          </div>
          <div className="footer-tab" onClick={this.props.onFeedbackClick}>
            Leave Feedback
          </div>
        </div>
        <div className="footer-main">
          <div className="footer-text">
            v1.22 - 2023/08/10 - Written by <a href="https://maxwoghiren.com">Max Woghiren</a>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
