import React, { Component } from 'react';

import Constants from '../../Constants';

import '../../styles/modals/LoginModal.css';

class LoginModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      password: "",
      passwordAgain: "",
      registering: false,
      errorMessage: null
    };
  }

  handleNameChange = (e) => {
    this.setState({
      name: e.target.value
    });
  }

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value
    });
  }

  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value
    });
  }

  handlePasswordAgainChange = (e) => {
    this.setState({
      passwordAgain: e.target.value
    });
  }

  handleRegisteringChange = (e) => {
    this.setState({
      registering: e.target.checked
    });
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const name = this.state.name;
    const email = this.state.email;
    const password = this.state.password;
    const passwordAgain = this.state.passwordAgain;
    const registering = this.state.registering;

    let dataJson;
    if (registering) {
      if (!name || !password || !passwordAgain) {
        this.setState({
          errorMessage: Constants.ERROR_MISSING_FIELDS
        });
        return;
      }

      if (name.length < 3 || name.length > 24) {
        this.setState({
          errorMessage: Constants.ERROR_PLAYER_NAME_INVALID
        });
        return;
      }

      if (password !== passwordAgain) {
        this.setState({
          errorMessage: Constants.ERROR_PASSWORDS_DO_NOT_MATCH
        });
        return;
      }

      const data = await fetch('/api/account/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name, email, password, passwordAgain }),
      });

      dataJson = await data.json();
      if (!dataJson.success) {
        this.setState({
          errorMessage: dataJson.error
        });
        return;
      }
    } else {
      const data = await fetch('/api/account/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name, password }),
      });

      dataJson = await data.json();
      if (!dataJson.success) {
        this.setState({
          errorMessage: "Invalid username / password."
        });
        return;
      }
    }

    this.setState({
      name: "",
      email: "",
      password: "",
      passwordAgain: "",
      registering: false,
      errorMessage: null
    });
    this.props.onSubmit(dataJson.data.token);
  }

  handleCancel = () => {
    this.props.onCancel();
  }

  render() {
    let className = "login-modal";
    if (this.state.registering) {
      className += " login-modal-register";
    }
    return (
      <div className={className}>
        <form className="login-form" onSubmit={this.handleSubmit}>
          <label className="label-name">Name:
            <input type="text" className="input" onChange={this.handleNameChange} />
          </label>
          <label className="label-password">Password:
            <input type="password" className="input" onChange={this.handlePasswordChange} />
          </label>
          <label className="label-registering">
            <input
              type="checkbox"
              checked={this.state.registering}
              onChange={this.handleRegisteringChange}
            />
            <span className="checkbox-span"></span>
            I'm registering a new account.
          </label>

          { this.state.registering &&
            <label className="label-password-again">Password Again:
              <input type="password" className="input" onChange={this.handlePasswordAgainChange} />
            </label>
          }
          { this.state.registering &&
            <label className="label-email">Email (optional):
              <input type="text" className="input" onChange={this.handleEmailChange} />
            </label>
          }

          <div className="login-modal-buttons">
            <button type="submit" className="login-modal-button">
              { this.state.registering ? "Register" : "Log In" }
            </button>
            <button className="login-modal-button" onClick={this.handleCancel}>
              Cancel
            </button>
          </div>
        </form>

        { !!this.state.errorMessage &&
          <div className="login-modal-error">
            { this.state.errorMessage }
          </div>
        }
      </div>
    );
  }
}

export default LoginModal;
