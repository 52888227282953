import React, { Component } from 'react';

import HoldCell from './HoldCell';

import '../styles/HoldCellDisplay.css';

class HoldCellDisplay extends Component {
  render() {
    return (
      <div className="hold-cell-display">
        <fieldset className="hold-fieldset">
          <legend className="hold-legend">
            Holds
          </legend>
          <div className="hold-cell-row">
            {this.props.letters && this.props.letters.map((letter, i) => {
              return (
                <HoldCell
                  key={`hold_cell_${i}`}
                  index={i}
                  letter={letter}
                  selected={i === this.props.selectedIndex}
                  onLetterHold={this.props.onLetterHold}
                  onHeldLetterSelection={this.props.onHeldLetterSelection}
                />
              )
            })}
          </div>
        </fieldset>
      </div>
    );
  }
}

export default HoldCellDisplay;
