import React, { Component } from 'react';

import Utils from '../Utils';

import '../styles/DatePanel.css';

class DatePanel extends Component {
  render() {
    return (
      <div className="date-panel">
        {Utils.getReadableDateStringToday()}
      </div>
    );
  }
}

export default DatePanel;
