import React, { Component } from 'react';

import Scoreboard from '../Scoreboard';

import '../../styles/modals/LeaderboardModal.css';

class LeaderboardModal extends Component {
  handleScoreboardClick = (playerName, date) => {
    this.props.onClose();
    this.props.onClick(playerName, date);
  }

  render() {
    return (
      <div className="leaderboard-modal">
        <div className="leaderboard-modal-message">
          Note: Try tapping a score to see its board!
        </div>
        <Scoreboard
          clickableToday={this.props.clickableToday}
          scores={this.props.scores}
          starMap={this.props.yesterdayStarMap}
          trophyMap={this.props.lastWeekTrophyMap}
          onClick={this.handleScoreboardClick}
        />

        <button className="leaderboard-modal-close" onClick={this.props.onClose}>
          Close
        </button>
      </div>
    );
  }
}

export default LeaderboardModal;
