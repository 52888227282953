import React, { Component } from 'react';

import Constants from '../Constants';
import Utils from '../Utils';

import '../styles/ControlPanel.css';

class ControlPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedGameType: Constants.GAME_TYPE_DAILY,
      rows: Constants.DEFAULT_ROWS,
      columns: Constants.DEFAULT_COLUMNS,
      selectedLetterOptionsPerRound: Constants.DEFAULT_LETTER_OPTIONS_PER_ROUND,
      selectedStartLetterCount: Constants.DEFAULT_START_LETTER_COUNT,
      selectedHoldCount: Constants.DEFAULT_HOLD_COUNT,
      selectedPreviewCount: Constants.DEFAULT_PREVIEW_COUNT,
      selectedExpansionCount: Constants.DEFAULT_EXPANSION_COUNT,
      selectedAdjacencyMode: false,
      selectedIncrementMode: false,
      selectedDropMode: false,
      errorMessage: null
    };
  }

  handleSelectedGameTypeChange = (e) => {
    const selectedGameType = e.target.value;

    this.setState({
      selectedGameType,
      errorMessage: null
    });
  }

  handleSelectedGridSizeChange = (e) => {
    const [rows, columns] = e.target.value.split("⨉");

    this.setState({
      rows: parseInt(rows, 10),
      columns: parseInt(columns, 10)
    });
  }

  handleSelectedLetterOptionsPerRoundChange = (e) => {
    const selectedLetterOptionsPerRound = parseInt(e.target.value, 10);
    let { selectedHoldCount, selectedPreviewCount } = this.state;

    if (selectedLetterOptionsPerRound === 1) {
      selectedHoldCount = 0;
    }
    if (selectedLetterOptionsPerRound < selectedPreviewCount) {
      selectedPreviewCount = selectedLetterOptionsPerRound;
    }

    this.setState({
      selectedLetterOptionsPerRound,
      selectedHoldCount,
      selectedPreviewCount
    });
  }

  handleSelectedStartLetterCountChange = (e) => {
    this.setState({
      selectedStartLetterCount: parseInt(e.target.value, 10)
    });
  }

  handleSelectedExpansionCountChange = (e) => {
    this.setState({
      selectedExpansionCount: parseInt(e.target.value, 10)
    });
  }

  handleSelectedHoldCountChange = (e) => {
    this.setState({
      selectedHoldCount: parseInt(e.target.value, 10)
    });
  }

  handleSelectedPreviewCountChange = (e) => {
    this.setState({
      selectedPreviewCount: parseInt(e.target.value, 10)
    });
  }

  handleSelectedAdjacencyModeChange = (e) => {
    this.setState({
      selectedAdjacencyMode: e.target.checked
    });
  }

  handleSelectedIncrementModeChange = (e) => {
    this.setState({
      selectedIncrementMode: e.target.checked
    });
  }

  handleSelectedDropModeChange = (e) => {
    this.setState({
      selectedDropMode: e.target.checked
    });
  }

  handleDailyFirstPlayChange = (e) => {
    this.setState({
      dailyFirstPlay: e.target.checked
    });
  }

  handlePlaySubmit = async (e) => {
    e.preventDefault();

    if (this.state.selectedGameType === Constants.GAME_TYPE_DAILY) {
      if (!this.props.playerName) {
        this.setState({
          errorMessage: Constants.ERROR_NOT_LOGGED_IN
        });
        return;
      }

      if (await this.checkForBan()) {
        this.setState({
          errorMessage: Constants.ERROR_PLAYER_BANNED
        });
        this.recordIllegalPlay();
        return;
      }
    }

    this.setState({
      errorMessage: null
    });

    let rows = this.state.rows;
    let columns = this.state.columns;
    let letterOptionsPerRound = this.state.selectedLetterOptionsPerRound;
    let startLetterCount = this.state.selectedStartLetterCount;
    let holdCount = this.state.selectedHoldCount;
    let previewCount = this.state.selectedPreviewCount;
    let expansionCount = this.state.selectedExpansionCount;
    let adjacencyMode = this.state.selectedAdjacencyMode;
    let incrementMode = this.state.selectedIncrementMode;
    let dropMode = this.state.selectedDropMode;
    if (this.state.selectedGameType === Constants.GAME_TYPE_DAILY) {
      const day = Utils.getDayOfWeekToday();
      rows = Constants.DAILY_ROWS[day];
      columns = Constants.DAILY_COLUMNS[day];
      letterOptionsPerRound = Constants.DAILY_LETTER_OPTIONS_PER_ROUND[day];
      startLetterCount = Constants.DAILY_START_LETTER_COUNT[day];
      holdCount = Constants.DAILY_HOLDS[day];
      previewCount = Constants.DAILY_PREVIEWS[day];
      expansionCount = Constants.DAILY_EXPANSIONS[day];
      adjacencyMode = Constants.DAILY_ADJACENCY_MODE[day];
      incrementMode = Constants.DAILY_INCREMENT_MODE[day];
      dropMode = Constants.DAILY_DROP_MODE[day];
    }

    this.props.onStartButtonClick(
      this.state.selectedGameType,
      /* liveGame */ true,
      /* spectating */ null,
      Utils.getDateStringToday(),
      rows,
      columns,
      letterOptionsPerRound,
      startLetterCount,
      holdCount,
      previewCount,
      expansionCount,
      adjacencyMode,
      incrementMode,
      dropMode,
    );
  }

  handleContinueSubmit = async (e) => {
    e.preventDefault();

    const date = Utils.getDateStringToday();
    const playerName = this.props.playerName;
    const data = await fetch(`/api/attempts/date/${date}/player/${playerName}`);
    const dataJson = await data.json();
    if (dataJson.data.length < 1) {
      this.setState({
        errorMessage: Constants.ERROR_NO_ATTEMPT_FOUND
      });
      return;
    } else if (dataJson.data.length > 1) {
      this.setState({
        errorMessage: Constants.ERROR_MULTIPLE_ATTEMPTS_FOUND
      });
      return;
    }

    const attempt = dataJson.data[0];
    this.props.onContinueButtonClick(attempt.boardString, attempt.elapsedTurns, attempt.holdString);
  }

  handleReviewSubmit = () => {
    this.props.onReviewButtonClick();
  }

  handleLeaderboardClick = () => {
    this.props.onLeaderboardButtonClick();
  }

  handleRulesClick = () => {
    this.props.onRulesButtonClick();
  }

  checkForBan = async () => {
    const banResponse = await fetch(`/api/banCheck`);
    const banResponseJson = await banResponse.json();
    return banResponseJson.banned;
  }

  recordIllegalPlay = async () => {
    const playerName = this.props.playerName;
    const date = Utils.getDateStringToday();

    const data = await fetch('/api/illegalPlays', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ playerName, date }),
    });
    const dataJson = await data.json();
    if (!dataJson.success) console.log("Failed to record illegal play.");
  }

  render() {
    const gameTypeRadioForm = (
      <div className="control-panel-row">
        <fieldset className="control-panel-fieldset">
          <legend className="control-panel-legend">
            Game Type
          </legend>
          <div className="control-panel-fieldset-inner">
            <label className="control-panel-label">
              <input
                type="radio"
                value="daily"
                checked={this.state.selectedGameType === Constants.GAME_TYPE_DAILY}
                onChange={this.handleSelectedGameTypeChange}
              />
              <span className="radio-span"></span>
              Daily
            </label>
            <label className="control-panel-label">
              <input
                type="radio"
                value="practice"
                checked={this.state.selectedGameType === Constants.GAME_TYPE_PRACTICE}
                onChange={this.handleSelectedGameTypeChange}
              />
              <span className="radio-span"></span>
              Free Play
            </label>
          </div>
        </fieldset>
      </div>
    );

    const gridSizeRadioForm = (
      <div className="control-panel-row">
        <fieldset className="control-panel-fieldset">
          <legend className="control-panel-legend">
            Grid Size
          </legend>
          <div className="control-panel-fieldset-inner">
            { ["4⨉7", "5⨉5", "5⨉6", "5⨉7", "6⨉6", "6⨉7", "7⨉7"].map((gridSize) => {
              return (
                <label key={gridSize}>
                  <input
                    type="radio"
                    value={gridSize}
                    checked={`${this.state.rows}⨉${this.state.columns}` === gridSize}
                    onChange={this.handleSelectedGridSizeChange}
                  />
                  <span className="radio-span"></span>
                  {gridSize}
                </label>
              );
            })}
          </div>
        </fieldset>
      </div>
    );

    const letterOptionsPerRoundRadioForm = (
      <div className="control-panel-row">
        <fieldset className="control-panel-fieldset">
          <legend className="control-panel-legend">
            Letter Options Per Round
          </legend>
          <div className="control-panel-fieldset-inner">
            { [1, 2, 3].map((letterOptionsPerRound) => {
              return (
                <label key={letterOptionsPerRound}>
                  <input
                    type="radio"
                    value={"" + letterOptionsPerRound}
                    checked={this.state.selectedLetterOptionsPerRound === letterOptionsPerRound}
                    onChange={this.handleSelectedLetterOptionsPerRoundChange}
                  />
                  <span className="radio-span"></span>
                  {letterOptionsPerRound}
                </label>
              );
            })}
          </div>
        </fieldset>
      </div>
    );

    const startLetterCountRadioForm = (
      <div className="control-panel-row">
        <fieldset className="control-panel-fieldset">
          <legend className="control-panel-legend">
            Preset Letters
          </legend>
          <div className="control-panel-fieldset-inner">
            { [0, 1, 2, 3, 4].map((startLetterCount) => {
              return (
                <label key={startLetterCount}>
                  <input
                    type="radio"
                    value={"" + startLetterCount}
                    checked={this.state.selectedStartLetterCount === startLetterCount}
                    onChange={this.handleSelectedStartLetterCountChange}
                  />
                  <span className="radio-span"></span>
                  {startLetterCount}
                </label>
              );
            })}
          </div>
        </fieldset>
      </div>
    );

    const expansionCountRadioForm = (
      <div className="control-panel-row">
        <fieldset className="control-panel-fieldset">
          <legend className="control-panel-legend">
            Expanded Cells
          </legend>
          <div className="control-panel-fieldset-inner">
            { [0, 1, 2, 3, 4].map((expansionCount) => {
              return (
                <label key={expansionCount}>
                  <input
                    type="radio"
                    value={"" + expansionCount}
                    checked={this.state.selectedExpansionCount === expansionCount}
                    onChange={this.handleSelectedExpansionCountChange}
                  />
                  <span className="radio-span"></span>
                  {expansionCount}
                </label>
              );
            })}
          </div>
        </fieldset>
      </div>
    );

    const holdCountRadioForm = (
      <div className="control-panel-row">
        <fieldset className="control-panel-fieldset">
          <legend className="control-panel-legend">
            Hold Spaces
          </legend>
          <div className="control-panel-fieldset-inner">
            { [0, 1, 2].map((holdSpaces) => {
              return (
                <label key={holdSpaces}>
                  <input
                    type="radio"
                    value={"" + holdSpaces}
                    disabled={this.state.selectedLetterOptionsPerRound === 1 && holdSpaces > 0}
                    checked={this.state.selectedHoldCount === holdSpaces}
                    onChange={this.handleSelectedHoldCountChange}
                  />
                  <span className="radio-span"></span>
                  {holdSpaces}
                </label>
              );
            })}
          </div>
        </fieldset>
      </div>
    );

    const previewCountRadioForm = (
      <div className="control-panel-row">
        <fieldset className="control-panel-fieldset">
          <legend className="control-panel-legend">
            Preview Tiles
          </legend>
          <div className="control-panel-fieldset-inner">
            { [0, 1, 2].map((previewTiles) => {
              return (
                <label key={previewTiles}>
                  <input
                    type="radio"
                    value={"" + previewTiles}
                    disabled={previewTiles > this.state.selectedLetterOptionsPerRound}
                    checked={this.state.selectedPreviewCount === previewTiles}
                    onChange={this.handleSelectedPreviewCountChange}
                  />
                  <span className="radio-span"></span>
                  {previewTiles}
                </label>
              );
            })}
          </div>
        </fieldset>
      </div>
    );

    const gameOptionsCheckboxForm = (
      <div className="control-panel-row">
        <fieldset className="control-panel-fieldset control-panel-fieldset-options">
          <legend className="control-panel-legend">
            Game Options
          </legend>
          <div className="control-panel-fieldset-inner control-panel-fieldset-inner-options">
            <label className="control-panel-label-option" key="adjacencyMode">
              <input
                type="checkbox"
                checked={this.state.selectedAdjacencyMode}
                onChange={this.handleSelectedAdjacencyModeChange}
              />
              <span className="checkbox-span"></span>
              Adjacency Mode
            </label>
            <label className="control-panel-label-option" key="incrementMode">
              <input
                type="checkbox"
                checked={this.state.selectedIncrementMode}
                onChange={this.handleSelectedIncrementModeChange}
              />
              <span className="checkbox-span"></span>
              Increment Mode
            </label>
            <label className="control-panel-label-option" key="dropMode">
              <input
                type="checkbox"
                checked={this.state.selectedDropMode}
                onChange={this.handleSelectedDropModeChange}
              />
              <span className="checkbox-span"></span>
              Drop Mode
            </label>
          </div>
        </fieldset>
      </div>
    );

    let startButtonRow;
    if (this.state.selectedGameType === Constants.GAME_TYPE_PRACTICE) {
      startButtonRow = (
        <div className="button-row">
          <button onClick={this.handlePlaySubmit}>
            Play!
          </button>
        </div>
      );
    } else {
      if (!this.props.playerName) {
        startButtonRow = (
          <div className="button-row">
            <button onClick={this.props.onLoginClick}>
              Log in!
            </button>
          </div>
        );
      } else {
        if (this.props.dailyAttempted === Constants.ATTEMPT_COMPLETE) {
          startButtonRow = (
            <div className="button-row control-panel-played">
              <div className="horizontal-rule no-mobile" />
              <div>
                You scored <span class="daily-score">{this.props.dailyScore}</span> on today's daily.
              </div>
              <div className="control-panel-buttons">
                <button className="button-control-panel" onClick={this.handleReviewSubmit}>
                  Review
                </button>
                <button className="button-control-panel" onClick={this.handlePlaySubmit}>
                  Try again!
                </button>
              </div>
            </div>
          );
        } else if (this.props.dailyAttempted === Constants.ATTEMPT_INCOMPLETE) {
          startButtonRow = (
            <div className="button-row control-panel-played">
              You have an incomplete game on record.<br />
              <button className="button-continue" onClick={this.handleContinueSubmit}>
                Continue!
              </button>
            </div>
          );
        } else {
          startButtonRow = (
            <div className="button-row">
              <button onClick={this.handlePlaySubmit}>
                Play!
              </button>
            </div>
          );
        }
      }
    }

    const leaderboardButtonRow = (
      <div className="button-row mobile-only">
        <button onClick={this.handleLeaderboardClick}>
          Leaderboards
        </button>
      </div>
    );

    const rulesButtonRow = (
      <div className="button-row mobile-and-tablet-only">
        <button onClick={this.handleRulesClick}>
          Rules
        </button>
      </div>
    );

    return (
      <div className="control-panel">
        { gameTypeRadioForm }
        { this.state.selectedGameType === Constants.GAME_TYPE_DAILY && !!this.props.playerName &&
          <>
            <div className="control-panel-row">
              <fieldset className="control-panel-fieldset">
                <legend className="control-panel-legend">
                  Name
                </legend>
                <div className="control-panel-fieldset-inner">
                  <div className="control-panel-logged-in-user-name">
                    { this.props.playerName }
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="control-panel-seven-days no-mobile">
              Your scores this week:<br />
              <span class="seven-day-scores">{this.props.sevenDayScoreString}</span>
            </div>
          </>
        }
        { this.state.selectedGameType === Constants.GAME_TYPE_PRACTICE &&
          gridSizeRadioForm
        }
        { this.state.selectedGameType === Constants.GAME_TYPE_PRACTICE &&
          letterOptionsPerRoundRadioForm
        }
        { this.state.selectedGameType === Constants.GAME_TYPE_PRACTICE &&
          startLetterCountRadioForm
        }
        { this.state.selectedGameType === Constants.GAME_TYPE_PRACTICE &&
          expansionCountRadioForm
        }
        { this.state.selectedGameType === Constants.GAME_TYPE_PRACTICE &&
          holdCountRadioForm
        }
        { this.state.selectedGameType === Constants.GAME_TYPE_PRACTICE &&
          previewCountRadioForm
        }
        { this.state.selectedGameType === Constants.GAME_TYPE_PRACTICE &&
          gameOptionsCheckboxForm
        }
        { startButtonRow }
        { rulesButtonRow }
        { this.state.selectedGameType === Constants.GAME_TYPE_DAILY &&
          leaderboardButtonRow
        }
        { this.state.errorMessage &&
          <div className="control-panel-row error-message">
            {this.state.errorMessage}
          </div>
        }
      </div>
    );
  }
}

export default ControlPanel;
