import React, { Component } from 'react';

import Score from './Score';

import '../styles/ScoreGroup.css';

class ScoreGroup extends Component {
  render() {
    return (
      <div className={"score-group score-group-" + this.props.direction}>
        {this.props.scores && this.props.scores.map((score, i) => {
          return (
            <Score key={`score_${i}`} score={this.props.scores[i]} />
          );
        })}
      </div>
    );
  }
}

export default ScoreGroup;
