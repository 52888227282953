import React, { Component } from 'react';

import '../styles/MessagePanel.css';

class MessagePanel extends Component {
  render() {
    return (
      <div className="message-panel">
        {this.props.message}
      </div>
    );
  }
}

export default MessagePanel;
