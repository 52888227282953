import React, { Component } from 'react';

import PreviewCell from './PreviewCell';

import '../styles/PreviewCellDisplay.css';

class PreviewCellDisplay extends Component {
  render() {
    return (
      <div className="preview-cell-display">
        <fieldset className="preview-fieldset">
          <legend className="preview-legend">
            Next
          </legend>
          <div className="preview-cell-row">
            {this.props.letters && this.props.letters.map((letter, i) => {
              return (
                <PreviewCell key={`preview_cell_${i}`} letter={letter} />
              )
            })}
          </div>
        </fieldset>
      </div>
    );
  }
}

export default PreviewCellDisplay;
