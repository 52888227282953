const Constants = {
  GAME_TYPE_DAILY: "daily",
  GAME_TYPE_PRACTICE: "practice",

  WORD_POSITION_NONE: "none",
  WORD_POSITION_START: "start",
  WORD_POSITION_MIDDLE: "middle",
  WORD_POSITION_END: "end",

  SCORES_TODAY: "today",
  SCORES_YESTERDAY: "yesterday",
  SCORES_THIS_WEEK: "this-week",
  SCORES_LAST_WEEK: "last-week",

  PENALTY_HOLD: -3,

  SCORE_TABLE: [0, 0, 0, 1, 2, 4, 6, 8, 10, 12, 15, 18],

  DEFAULT_ROWS: 5,
  DEFAULT_COLUMNS: 5,
  DEFAULT_LETTER_OPTIONS_PER_ROUND: 2,
  DEFAULT_START_LETTER_COUNT: 0,
  DEFAULT_HOLD_COUNT: 0,
  DEFAULT_PREVIEW_COUNT: 0,
  DEFAULT_EXPANSION_COUNT: 0,

  // 0 = Sunday.
  DAYS: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  DAILY_ROWS:                     [7, 5, 5, 5, 6, 4, 5],
  DAILY_COLUMNS:                  [7, 5, 5, 6, 6, 7, 5],
  DAILY_LETTER_OPTIONS_PER_ROUND: [2, 2, 2, 2, 3, 2, 2],
  DAILY_START_LETTER_COUNT:       [2, 1, 1, 2, 2, 0, 1],
  DAILY_HOLDS:                    [1, 0, 0, 0, 0, 1, 1],
  DAILY_PREVIEWS:                 [1, 0, 0, 1, 0, 0, 0],
  DAILY_EXPANSIONS:               [0, 0, 2, 0, 2, 0, 3],
  DAILY_ADJACENCY_MODE:           [true,  false, false, true,  false, false, false],
  DAILY_INCREMENT_MODE:           [false, false, false, false, false, false, true],
  DAILY_DROP_MODE:                [false, false, false, false, false, true,  false],

  ERROR_PLAYER_NAME_INVALID: "Please enter a name between 3 and 24 characters long.",
  ERROR_DAILY_ATTEMPT_RECORDED: "You've already played today's daily!",
  ERROR_PLAYER_BANNED: "It looks like you've been playing the daily multiple times per day under different names.\nPlease don't do this—it's not fair to players who follow the rules and play just once per day.\nAccess to the daily will be restored in a couple of days.",
  ERROR_MISSING_FIELDS: "Please fill out all mandatory fields.",
  ERROR_MUST_BE_FIRST_PLAY: "Please check the box if this is your first attempt at today's daily.\nIf it isn't, feel free to play Free Play mode!",
  ERROR_NOT_LOGGED_IN: "You must be logged in to play the daily.",
  ERROR_PASSWORDS_DO_NOT_MATCH: "Your passwords do not match.",
  ERROR_NO_ATTEMPT_FOUND: "No attempt found.\nPlease leave feedback or email max.woghiren@gmail.com to let me know this happened!",
  ERROR_MULTIPLE_ATTEMPTS_FOUND: "Multiple attempts found.\nPlease leave feedback or email max.woghiren@gmail.com to let me know this happened!",

  ATTEMPT_COMPLETE: "complete",
  ATTEMPT_INCOMPLETE: "incomplete",
  ATTEMPT_NONE: "none",

  MESSAGE_ADJACENCY_MODE: "All letters (except the first) must be placed adjacent to an existing one.",
  MESSAGE_INCREMENT_MODE: "The number of letter options each turn follows a 1-2-3 pattern.",
  MESSAGE_DROP_MODE: "Each placed letter drops to the lowest available cell in its column.",
  MESSAGE_EXPANDED: "Green cells accept two letters. Words using these cells must use both letters, in the order that they're placed.",

  MESSAGE_CELL_ALREADY_OCCUPIED: "That cell is already occupied.",
  MESSAGE_CELL_NOT_ADJACENT: "That cell is not adjacent to an occupied cell.",
  MESSAGE_NO_LETTER_SELECTED: "No letter has been selected.",
  MESSAGE_COLUMN_FULL: "That column is full.",

  LETTER_BLANK: "-",

  // From https://en.wikipedia.org/wiki/Letter_frequency#Relative_frequencies_of_letters_in_the_English_language.
  FREQUENCIES: [
    { letter: "A", frequency: 8167 },
    { letter: "B", frequency: 1492 },
    { letter: "C", frequency: 2782 },
    { letter: "D", frequency: 4253 },
    { letter: "E", frequency: 12702 },
    { letter: "F", frequency: 2228 },
    { letter: "G", frequency: 2015 },
    { letter: "H", frequency: 6094 },
    { letter: "I", frequency: 6966 },
    { letter: "J", frequency: 153 },
    { letter: "K", frequency: 772 },
    { letter: "L", frequency: 4025 },
    { letter: "M", frequency: 2406 },
    { letter: "N", frequency: 6749 },
    { letter: "O", frequency: 7507 },
    { letter: "P", frequency: 1929 },
    { letter: "Q", frequency: 95 },
    { letter: "R", frequency: 5987 },
    { letter: "S", frequency: 6327 },
    { letter: "T", frequency: 9056 },
    { letter: "U", frequency: 2758 },
    { letter: "V", frequency: 978 },
    { letter: "W", frequency: 2360 },
    { letter: "X", frequency: 150 },
    { letter: "Y", frequency: 1974 },
    { letter: "Z", frequency: 74 }
  ],

  GOLD: "gold",
  SILVER: "silver",
  BRONZE: "bronze",

  MODAL_NONE: "none",
  MODAL_FEEDBACK: "feedback",
  MODAL_FEEDBACK_THANKS: "feedback-thanks",
  MODAL_LEADERBOARD: "leaderboard",
  MODAL_LOGIN: "login",
  MODAL_PROFILE: "profile",
  MODAL_RULES: "rules",
  MODAL_SCHEDULE: "schedule",

  MODE_NAME_ADJACENCY: "Adjacency Mode",
  MODE_NAME_DROP: "Drop Mode",
  MODE_NAME_INCREMENT: "Increment Mode",
  MODE_NAME_STANDARD: "Standard Mode",
}

Constants.SCORE_PERIODS = [
  Constants.SCORES_TODAY,
  Constants.SCORES_YESTERDAY,
  Constants.SCORES_THIS_WEEK,
  Constants.SCORES_LAST_WEEK
];

Constants.ACCOLADE_COLORS = [
  Constants.GOLD,
  Constants.SILVER,
  Constants.BRONZE
]

Constants.SCORE_DESCRIPTORS = [
  "top",
  "second-highest",
  "third-highest"
]

export default Constants;
