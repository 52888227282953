import React, { Component } from 'react';

import NotePanel from './NotePanel';

import '../styles/Header.css';

class Header extends Component {
  render() {
    return (
      <div className="header">
        <div className="header-title">
          <a href="https://play.tenwords.net">Ten Words</a>
        </div>
        <NotePanel />
        { !!this.props.loggedInPlayerName &&
          <div className="header-welcome">
            <div className="header-user-name">
              Welcome, <span className="header-user-name-span" onClick={this.props.onProfileClick}>{this.props.loggedInPlayerName}</span>!
            </div>
            <div className="header-log-out" onClick={this.props.onLogoutClick}>
              [ Log Out ]
            </div>
          </div>
        }
        { !this.props.loggedInPlayerName &&
          <div className="header-log-in" onClick={this.props.onLoginClick}>
            [ Log In ]
          </div>
        }
      </div>
    );
  }
}

export default Header;
